import { i18n } from '../../i18n';
import { Config } from '../base/portal_env_config/config';
import AnalyticsConstant from '../clevertap/analyticsconstants';
import CleverTapUtil from '../clevertap/clevertaputil';
import LayoutTypes from '../components/layout/layout.types';
import { LoginService } from '../components/login/login.service';
import { LoginDetails } from '../model/login/loginDetails';
import AuthGuard from './auth.guard';
import AuthTypes from './auth.types';
import StorageUtil from './util/storageUtil';

export default class Auth extends AuthGuard {

    public static login(loginDetails: LoginDetails): Promise<any> {
        return new Promise((resolve, reject) => {
            LoginService.loginPortal(loginDetails).then(res => {
                if ((res.statusCode == 200 || res.statusCode == 201) && (res.data.statusCode == 200 || res.data.statusCode == 201)) {
                    if (!res?.data?.selectPrimaryUser) {
                        Auth.setLoginResponse(res.data);
                        StorageUtil.setDataLocalStorage(StorageUtil.LOGIN_KEY, res.data);
                        this.setLanguage(res.data.profileId);
                    }
                }
                resolve(res);
            })
                .catch((err) => {
                    if (err) {
                        console.log(err);
                        reject(err);
                    }
                });
        })
    };

    public static loginWithEmail(loginDetails: LoginDetails): Promise<any> {
        return new Promise((resolve, reject) => {
            LoginService.loginPortalWithEmail(loginDetails).then(res => {
                if ((res.statusCode == 200 || res.statusCode == 201) && (res.data.statusCode == 200 || res.data.statusCode == 201)) {
                    console.log('checkloginresponse')
                    Auth.setLoginResponse(res.data);
                    StorageUtil.setDataLocalStorage(StorageUtil.LOGIN_KEY, res.data);
                    this.setLanguage(res.data.profileId);
                }
                resolve(res);
            })
                .catch((err) => {
                    if (err) {
                        console.log(err);
                        reject(err);
                    }
                });
        })
    };

    public static getUserDetails() {
        return Auth.getAuthState()?.auth?.userDetails;
    }

    public static setLanguage(profileId) {
        let de_ln: string = StorageUtil.getStringDataLocalStorage(StorageUtil.DEFAULT_LANGUAGE + profileId);
        de_ln ? i18n.language = (de_ln) : i18n.language = (Config.portal.defaultLanguage);
        de_ln ? i18n.changeLanguage(de_ln) : i18n.changeLanguage(Config.portal.defaultLanguage);
    }

    public static setLoginResponse(data: any) {
        this.getDispatchState({ type: AuthTypes.SET_LOGIN_RESPONSE, payload: data });
        return data;
    }
    public static calculateLinks(links: any[]) {
        if (!links) {
            return [];
        }
        links = links.filter((link) => link.disabled !== true);
        links.sort((a, b) => {
            if (+a.priority < +b.priority)
                return -1;
            if (+a.priority > +b.priority)
                return 1;
            return 0;
        });
        links?.forEach((link) => {
            if (link.subsections?.length > 0)
                link.subsections = Auth.calculateLinks(link.subsections);
        });
        return links;
    }

    public static logout(req?): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getDispatchState({ type: LayoutTypes.SET_LAYOUT_SPINNER, payload: true });
            if (req) {
                LoginService.logout(req).then((res) => {
                    CleverTapUtil.onButtonClick(AnalyticsConstant.ACTION_LOGOUT_CLICK);
                    Auth.getDispatchState({ type: AuthTypes.SET_LOGOUT });
                    StorageUtil.clearData();
                    this.getDispatchState({ type: 'USER_LOGGED_OUT' });
                    this.getDispatchState({ type: LayoutTypes.SET_LAYOUT_SPINNER, payload: false });
                    i18n.changeLanguage(Config.portal.defaultLanguage);
                    resolve(res);
                }).catch((err) => {
                    if (err) {
                        Auth.getDispatchState({ type: AuthTypes.SET_LOGOUT });
                        this.getDispatchState({ type: LayoutTypes.SET_LAYOUT_SPINNER, payload: false });
                        console.log(err);
                        reject(err);
                    }
                });
            } else {
                Auth.getDispatchState({ type: AuthTypes.SET_LOGOUT });
                StorageUtil.clearData();
            }
        })
    };


    public static getSessionId(): string {
        if (Auth.getAuthState()?.auth?.token)
            return Auth.getAuthState()?.auth?.token;
        else return '';
    }

}