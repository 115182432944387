
export class AppConstants {

    public static POZ_BASE_URL_INDEX: number = 0;
    public static CONQUEST_BASE_URL_INDEX: number = AppConstants.POZ_BASE_URL_INDEX + 1;
    public static ELASTIC_URL_INDEX: number = AppConstants.CONQUEST_BASE_URL_INDEX + 1;
    public static CHAT_URL_INDEX = AppConstants.ELASTIC_URL_INDEX + 1;

    //Dev
    private static DevIp = "http://localhost:8080";
    private static DEV_POZ_BASE_URL = AppConstants.DevIp + "/POZAppServices/";
    private static DEV_CONQUEST_URL = AppConstants.DevIp + "/HSignzAppServices/";
    private static DEV_ELASTIC_URL = "https://api-qa.healthsignz.net/search/";
    private static DEV_CHAT_URL = "https://api-vdcchat.healthsignz.net/";
    private static DEV_BYPASS_SESSION = false;
    private static DEV_BYPASS_ENCRYPTION = true;
    private static DEV_BASE_URL = [AppConstants.DEV_POZ_BASE_URL, AppConstants.DEV_CONQUEST_URL,
    AppConstants.DEV_ELASTIC_URL, AppConstants.DEV_CHAT_URL,
    AppConstants.DEV_BYPASS_SESSION, AppConstants.DEV_BYPASS_ENCRYPTION];

    //AWS_QA4
    private static AWS_QA_CONQUEST_BASE_URL = "https://api-qa4.healthsignz.net/HSignzAppServices/";
    private static AWS_QA_POZ_BASE_URL = "https://api-qa4.healthsignz.net/POZAppServices/";
    private static AWS_QA_ELASTIC_BASE_URL = "https://api-qa.healthsignz.net/search/";
    private static AWS_QA_CHAT_URL = "https://api-vdcchat.healthsignz.net/";
    private static AWS_QA_BYPASS_SESSION = false;
    private static AWS_QA_BYPASS_ENCRYPTION = true;
    private static AWS_QA_BASE_URL = [AppConstants.AWS_QA_POZ_BASE_URL, AppConstants.AWS_QA_CONQUEST_BASE_URL,
    AppConstants.AWS_QA_ELASTIC_BASE_URL, AppConstants.AWS_QA_CHAT_URL,
    AppConstants.AWS_QA_BYPASS_SESSION, AppConstants.AWS_QA_BYPASS_ENCRYPTION];

    //AWS_UAT
    private static AWS_UAT_BASE_POZ_URL = "https://api-uat.vijayadiagnostic.com/POZAppServices/";
    private static AWS_UAT_BASE_CONQUEST_URL = "https://api-uat.vijayadiagnostic.com/HSignzAppServices/";
    private static AWS_UAT_ELASTIC_URL = "https://api-uat.vijayadiagnostic.com/search/";
    private static AWS_UAT_CHAT_URL = "https://api-uat.vijayadiagnostic.com/";
    private static AWS_UAT_BYPASS_SESSION = true;
    private static AWS_UAT_BYPASS_ENCRYPTION = true;
    private static AWS_UAT_BASE_URL = [AppConstants.AWS_UAT_BASE_POZ_URL, AppConstants.AWS_UAT_BASE_CONQUEST_URL,
    AppConstants.AWS_UAT_ELASTIC_URL, AppConstants.AWS_UAT_CHAT_URL,
    AppConstants.AWS_UAT_BYPASS_SESSION, AppConstants.AWS_UAT_BYPASS_ENCRYPTION];

    //LIVE_VDC
    private static LIVE_CONQUEST_BASE_URL = "https://api-app.vijayadiagnostic.com/HSignzAppServices/";
    private static LIVE_POZ_BASE_URL = "https://api-app.vijayadiagnostic.com/POZAppServices/";
    private static LIVE_ELASTIC_BASE_URL = "https://api-search.vijayadiagnostic.com/";
    private static LIVE_CHAT_URL = "https://api-chat.vijayadiagnostic.com/";
    private static LIVE_BYPASS_SESSION = true;
    private static LIVE_BYPASS_ENCRYPTION = true;
    private static LIVE_BASE_URL = [AppConstants.LIVE_POZ_BASE_URL, AppConstants.LIVE_CONQUEST_BASE_URL,
    AppConstants.LIVE_ELASTIC_BASE_URL, AppConstants.LIVE_CHAT_URL,
    AppConstants.LIVE_BYPASS_SESSION, AppConstants.LIVE_BYPASS_ENCRYPTION];



    public static BASE_URLS = [
        AppConstants.DEV_BASE_URL, AppConstants.AWS_QA_BASE_URL, AppConstants.AWS_UAT_BASE_URL,
        AppConstants.LIVE_BASE_URL];

    constructor() { }

}