import { BasketRequest } from '../model/basket/basketrequest';
import AnalyticsConstant from './analyticsconstants';
import { BasketDiscount } from '../model/package/basketdiscount';
import { Config } from '../base/portal_env_config/config';
import CommonUtil from '../base/utils/commonUtil';
// import ReactGA from 'react-ga4';

export default class CleverTapUtil {

    private static sendPixelEvent(event, eventData) {
        try {
            if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enablePixel) {
                const ReactPixel = require('react-facebook-pixel');
                //Pixel initialization
                const options = {
                    autoConfig: true,
                    debug: false,
                };
                ReactPixel.default.init(Config.portal?.pixelId, null, options);
                if (Config.portal?.enableCustomPixelEvents) {
                    ReactPixel.trackCustom(event, eventData);
                } else {
                    if (event && event.includes("Purchase Click")) {
                        ReactPixel.default.fbq('track', 'InitiateCheckout');
                    } else if (event && event.includes("Order Placed")) {
                        ReactPixel.default.fbq('track', 'Purchase', { value: eventData[AnalyticsConstant.FINAL_AMOUNT], currency: 'INR' });
                    }
                }
            }
        } catch (error) {
            console.error("Pixel error", error);
        }
    }

    private static sendNetcoreEvent(event, eventData) {
        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableNetcore) {
            if (eventData[AnalyticsConstant.PROFILE_PHONE]) {
                eventData['mobile'] = eventData[AnalyticsConstant.PROFILE_PHONE];
                delete eventData.Phone;
            }
            (<any>window).smartech('dispatch', event, eventData);
        }
    }

    private static updateNetcoreUserInfo(profileInfo) {
        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableNetcore) {
            (<any>window).smartech('contact', '4', {
                'pk^mobile': profileInfo.contactInfo.mobile,
                'email': profileInfo.contactInfo.email,
                'FIRST_NAME': profileInfo.fName,
                'LAST_NAME': profileInfo.lName
            });
        }
    }

    private static sendPixelPageView() {
        // if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enablePixel) {
        //     const ReactPixel = require('react-facebook-pixel');
        //     //Pixel initialization
        //     const options = {
        //         autoConfig: true,
        //         debug: false,
        //     };
        //     ReactPixel.default.init(Config.portal?.pixelId, null, options);
        //     ReactPixel.default.pageView();
        // }
    }

    public static detailsScreenClick(event, id, name, serviceId) {
        if (Config.portal?.enableClevertap) {
            (window as any).clevertap?.event?.push(event, {
                "label": name,
                "id": id
            });
        }
        if (Config.portal?.enableMoengage) {
            (window as any).Moengage?.track_event(event, {
                "label": name,
                "id": id
            });
        }
        if (Config.portal?.enableGTM && Config.TEST_TYPE >= Config.LIVE) {

            (window as any).dataLayer?.push({
                event: AnalyticsConstant.VIEW_ITEM,
                    ecommerce: {
                        items: [
                            {
                                item_id: serviceId,
                                item_name: name
                                
                            }
                        ]
                    }  
            });

        }
        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enablePixel) {
            this.sendPixelEvent(event, {
                "label": name,
                "id": id
            });
        }
        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableNetcore) {
            this.sendNetcoreEvent(event, {
                "label": name,
                "id": id
            });
        }
    }

    public static dashboardItemClick(serviceName: string, serviceId: number) {
        if (Config.portal?.enableClevertap) {
            (window as any).clevertap?.event?.push(AnalyticsConstant.DASHBOARD_ITEM_CLICK, {
                "screenName": AnalyticsConstant.DASHBOARD_ITEM_CLICK,
                "action": AnalyticsConstant.ACTION_BUTTON,
                "label": serviceName,
                "id": serviceId
            });
        }
        if (Config.portal?.enableMoengage) {
            (window as any).Moengage?.track_event(AnalyticsConstant.DASHBOARD_ITEM_CLICK, {
                "screenName": AnalyticsConstant.DASHBOARD_ITEM_CLICK,
                "action": AnalyticsConstant.ACTION_BUTTON,
                "label": serviceName,
                "id": serviceId
            });
        }
        // if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableGoogleAnaltyics) {
        //     (window as any).gtag('event', AnalyticsConstant.DASHBOARD_ITEM_CLICK, {
        //         "screenName": AnalyticsConstant.DASHBOARD_ITEM_CLICK,
        //         "action": AnalyticsConstant.ACTION_BUTTON,
        //         "label": serviceName,
        //         "id": serviceId
        //       });
        // }
        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enablePixel) {
            this.sendPixelEvent(AnalyticsConstant.DASHBOARD_ITEM_CLICK, {
                "screenName": AnalyticsConstant.DASHBOARD_ITEM_CLICK,
                "action": AnalyticsConstant.ACTION_BUTTON,
                "label": serviceName,
                "id": serviceId
            });
        }
        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableNetcore) {
            this.sendNetcoreEvent(AnalyticsConstant.DASHBOARD_ITEM_CLICK, {
                "screenName": AnalyticsConstant.DASHBOARD_ITEM_CLICK,
                "action": AnalyticsConstant.ACTION_BUTTON,
                "label": serviceName,
                "id": serviceId
            });
        }
    }

    public static onButtonClick(event) {
        if (Config.portal?.enableClevertap) {
            (window as any).clevertap?.event?.push(event, {
                "action": AnalyticsConstant.ACTION_BUTTON
            });
        }
        if (Config.portal?.enableMoengage) {
            (window as any).Moengage?.track_event(event, {
                "action": AnalyticsConstant.ACTION_BUTTON
            });
            (window as any).Moengage.destroy_session();
        }
        // if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableGoogleAnaltyics) {
        //     (window as any).gtag('event', event, {
        //         "action": AnalyticsConstant.ACTION_BUTTON
        //       });
        // }
        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enablePixel) {
            this.sendPixelEvent(event, {
                "action": AnalyticsConstant.ACTION_BUTTON
            });
        }
        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableNetcore) {
            this.sendNetcoreEvent(event, {
                "action": AnalyticsConstant.ACTION_BUTTON
            });
        }
    }

    public static onLoginClick(data) {
        if (Config.portal?.enableClevertap) {
            (<any>window).clevertap?.onUserLogin?.push({
                "Site": {
                    [AnalyticsConstant.PROFILE_NAME]: ((data.fName ?? "") + (data.lName ?? "")),
                    [AnalyticsConstant.PROFILE_PHONE]: "+91" + data.contactInfo?.mobile,
                    [AnalyticsConstant.PROFILE_GENDER]: (data.patientGender && data.patientGender == "Female") ? "F" : "M",
                    [AnalyticsConstant.PROFILE_AGE]: data.patientDOB ? CommonUtil.getYearOnlyAgeForall(data.patientDOB) : 0,
                    [AnalyticsConstant.PROFILE_ID]: data.profileId
                }
            });
        }
        if (Config.portal?.enableMoengage) {
            (window as any).Moengage.add_unique_user_id(data.profileId).then(() => {
                (window as any).Moengage.add_first_name(data.fName);
                (window as any).Moengage.add_last_name(data.lName);
                (window as any).Moengage.add_email(data.contactInfo?.email);
                (window as any).Moengage.add_mobile("+91" + data.contactInfo?.mobile);
                (window as any).Moengage.add_user_name((data.fName ?? "") + (data.lName ?? ""));
                (window as any).Moengage.add_gender(data.gender);
            })

        }
        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enablePixel) {
            this.sendPixelEvent(AnalyticsConstant.ACTION_LOGIN_SUCCESS, {
                [AnalyticsConstant.PROFILE_NAME]: ((data.fName ?? "") + (data.lName ?? "")),
                [AnalyticsConstant.PROFILE_PHONE]: "+91" + data.contactInfo?.mobile,
                [AnalyticsConstant.PROFILE_GENDER]: (data.patientGender && data.patientGender == "Female") ? "F" : "M",
                [AnalyticsConstant.PROFILE_AGE]: data.patientDOB ? CommonUtil.getYearOnlyAgeForall(data.patientDOB) : 0,
                [AnalyticsConstant.PROFILE_ID]: data.profileId
            });
        }
        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableNetcore) {
            (<any>window).smartech('identify', data?.contactInfo?.mobile);

            this.updateNetcoreUserInfo(data);

            let tmp = {
                'mobile': "+91" + data.contactInfo?.mobile
            };

            if (data.patientGender) {
                tmp[AnalyticsConstant.PROFILE_GENDER] = (data.patientGender && data.patientGender == "Female") ? "F" : "M";
            }
            if (data.patientDOB && data.patientDOB != 0) {
                tmp[AnalyticsConstant.PROFILE_AGE] = data.patientDOB ? CommonUtil.getYearOnlyAgeForall(data.patientDOB) : 0;
            }
            if (data.fName) {
                tmp[AnalyticsConstant.PROFILE_NAME] = ((data.fName ?? "") + (data.lName ?? ""));
            }
            this.sendNetcoreEvent(AnalyticsConstant.ACTION_LOGIN_SUCCESS, tmp);
        }
    }

    public static onProfileClick(event, data) {
        if (Config.portal?.enableClevertap) {
            (window as any).clevertap?.event?.push(event, {
                "label": AnalyticsConstant.ACTION_BUTTON,
                [AnalyticsConstant.PROFILE_NAME]: ((data.fName ?? "") + (data.lName ?? "")),
                [AnalyticsConstant.PROFILE_EMAIL]: data.contactInfo?.email ?? "",
                [AnalyticsConstant.PROFILE_PHONE]: data.contactInfo?.mobile,
                [AnalyticsConstant.PROFILE_GENDER]: (data.patientGender && data.patientGender == "Female") ? "F" : "M",
                [AnalyticsConstant.PROFILE_AGE]: data.patientDOB ? CommonUtil.getYearOnlyAgeForall(data.patientDOB) : 0,
                [AnalyticsConstant.PROFILE_PHR]: data.healthScore?.toString() ?? ""
            });
        }
        if (Config.portal?.enableMoengage) {
            (window as any).Moengage?.track_event(event, {
                "label": AnalyticsConstant.ACTION_BUTTON,
                [AnalyticsConstant.PROFILE_NAME]: ((data.fName ?? "") + (data.lName ?? "")),
                [AnalyticsConstant.PROFILE_EMAIL]: data.contactInfo?.email ?? "",
                [AnalyticsConstant.PROFILE_PHONE]: data.contactInfo?.mobile,
                [AnalyticsConstant.PROFILE_GENDER]: (data.patientGender && data.patientGender == "Female") ? "F" : "M",
                [AnalyticsConstant.PROFILE_AGE]: data.patientDOB ? CommonUtil.getYearOnlyAgeForall(data.patientDOB) : 0,
                [AnalyticsConstant.PROFILE_PHR]: data.healthScore?.toString() ?? ""
            });
        }
        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enablePixel) {
            this.sendPixelEvent(event, {
                "label": AnalyticsConstant.ACTION_BUTTON,
                [AnalyticsConstant.PROFILE_NAME]: ((data.fName ?? "") + (data.lName ?? "")),
                [AnalyticsConstant.PROFILE_EMAIL]: data.contactInfo?.email ?? "",
                [AnalyticsConstant.PROFILE_PHONE]: data.contactInfo?.mobile,
                [AnalyticsConstant.PROFILE_GENDER]: (data.patientGender && data.patientGender == "Female") ? "F" : "M",
                [AnalyticsConstant.PROFILE_AGE]: data.patientDOB ? CommonUtil.getYearOnlyAgeForall(data.patientDOB) : 0,
                [AnalyticsConstant.PROFILE_PHR]: data.healthScore?.toString() ?? ""
            });
        }

        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableNetcore) {
            (<any>window).smartech('identify', data?.contactInfo?.mobile);

            this.updateNetcoreUserInfo(data);

            this.sendNetcoreEvent(event, {
                "label": AnalyticsConstant.ACTION_BUTTON,
                [AnalyticsConstant.PROFILE_NAME]: ((data.fName ?? "") + (data.lName ?? "")),
                [AnalyticsConstant.PROFILE_EMAIL]: data.contactInfo?.email ?? "",
                [AnalyticsConstant.PROFILE_PHONE]: data.contactInfo?.mobile,
                [AnalyticsConstant.PROFILE_GENDER]: (data.patientGender && data.patientGender == "Female") ? "F" : "M",
                [AnalyticsConstant.PROFILE_AGE]: data.patientDOB ? CommonUtil.getYearOnlyAgeForall(data.patientDOB) : 0,
                [AnalyticsConstant.PROFILE_PHR]: data.healthScore?.toString() ?? ""
            });
        }
    }

    public static onScreenClick(event: string, label: string) {
        if (Config.portal?.enableClevertap) {
            (window as any).clevertap?.event?.push(event, {
                "label": label
            });
        }
        if (Config.portal?.enableMoengage) {
            (window as any).Moengage?.track_event(event, {
                "label": label
            });
        }
        // if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableGoogleAnaltyics) {
        //     (window as any).gtag('event', event, {
        //         "label": label
        //       });
        // }
        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enablePixel) {
            this.sendPixelEvent(event, {
                "label": label
            });
        }
        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableNetcore) {
            this.sendNetcoreEvent(event, {
                "label": label
            });
        }
    }
    public static onPageClick(event) {
        if (Config.portal?.enableClevertap) {
            (window as any).clevertap?.event?.push(event);
        }
        if (Config.portal?.enableMoengage) {
            (window as any).Moengage?.track_event(event);
        }
        // if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableGoogleAnaltyics) {
        //     (window as any).gtag('event', event);
        // }
        if (Config.portal?.enablePixel) {
            this.sendPixelPageView();
        }
        if (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableNetcore) {
            // (<any>window).smartech('dispatch', 'page browse', {});
            (<any>window).smartech('dispatch', 1, {});
        }
    }

    public static servicePurchase(basketRequest: BasketRequest, event) {
        console.log('basketRequest___', basketRequest, event)


        if (Config.portal?.enableClevertap || Config.portal?.enableMoengage || Config.portal?.enableGTM || (Config.TEST_TYPE >= Config.LIVE && Config.portal?.enablePixel)) {
            let eventData = null;
            let couponCode = "";
            let patientDetails = null;
            let totalAmountPaid = basketRequest?.totalAmountPaid;

            const slotBookingDetailsforGa4 = basketRequest
                // Merge all service lists into one array
                const AllServiceslist = slotBookingDetailsforGa4.slotBookingDetailsList.reduce((accumulator, slotDetails) => {
                    return accumulator.concat(slotDetails.serviceList);
                }, []);

                // Function to filter and update the service list
                function filterAndMergeServices(serviceList) {
                    const serviceMap = new Map();

                    // Iterate through the service list to filter and update
                    serviceList.forEach(service => {
                        const serviceId = service.serviceId;

                        if (serviceMap.has(serviceId)) {
                            // If the serviceId already exists, update the quantity
                            serviceMap.get(serviceId).quantity += 1;
                            serviceMap.get(serviceId).finalAmount += service.originalAmount;
                        } else {
                            // If the serviceId doesn't exist, add it to the map
                            serviceMap.set(serviceId, { ...service, quantity: 1 });
                        }
                    });

                    // Convert the map values back to an array
                    const filteredServiceList = Array.from(serviceMap.values());

                    return filteredServiceList;
                }

                // Filter and update the merged service list
                const ServicelistforGa4 = filterAndMergeServices(AllServiceslist);

                // Output the modified JSON data
                console.log('AllServiceslist---', AllServiceslist)
                console.log('ServicelistforGa4---', ServicelistforGa4)

            let isBookingSourceConsumerPortal = true;
            if (basketRequest.bookingSource == 3) {
                isBookingSourceConsumerPortal = false;
            }
            let transactionId = basketRequest?.transactionId;
            if (basketRequest?.slotBookingDetailsList && basketRequest.slotBookingDetailsList.length > 0 && basketRequest.slotBookingDetailsList[0]) {
                let slotBookingDetails = basketRequest.slotBookingDetailsList[0];
                patientDetails = slotBookingDetails.patientProfileDetails;
                slotBookingDetails.basketDiscount.forEach(discObj => {
                    if (discObj.type == BasketDiscount.COUPON_DISCOUNT)
                        couponCode = discObj.name;
                });
                eventData = {
                    // "action": AnalyticsConstant.ACTION_BUTTON,
                    ["Order ID"]: slotBookingDetails.orderId,
                    ["Invoice ID"]: slotBookingDetails.invoiceId,
                    ["BaseInvoice ID"]: slotBookingDetails.baseInvoiceId,
                    ["Coupon Code"]: couponCode,
                    [AnalyticsConstant.TEST_NAME]: slotBookingDetails.serviceList?.flatMap(x => [x.serviceName]).join(","),
                    [AnalyticsConstant.DOCTOR_NAME]: (slotBookingDetails.doctorDetail?.title ? slotBookingDetails.doctorDetail?.title : "") + " "
                        + (slotBookingDetails.doctorDetail?.firstName ? slotBookingDetails.doctorDetail?.firstName : "") + " "
                        + (slotBookingDetails.doctorDetail?.lastName ? slotBookingDetails.doctorDetail?.lastName : ""),
                    [AnalyticsConstant.SERVICE_NAME]: slotBookingDetails.serviceName,
                    [AnalyticsConstant.POC_ID]: slotBookingDetails.pocDetails.pocId,
                    [AnalyticsConstant.HOME_OR_WALKIN]: slotBookingDetails.bookingSubType == 0 ? 'WALKIN' : 'Home',
                    [AnalyticsConstant.POC_NAME]: slotBookingDetails.pocDetails.pocName,
                    [AnalyticsConstant.PATIENT_ID]: slotBookingDetails.patientProfileId?.toString(),
                    [AnalyticsConstant.PATIENT_NAME]: (slotBookingDetails.patientProfileDetails.fName ? slotBookingDetails.patientProfileDetails.fName : "")
                        + " " + (slotBookingDetails.patientProfileDetails.lName ? slotBookingDetails.patientProfileDetails.lName : ""),
                    [AnalyticsConstant.PROFILE_PHONE]: slotBookingDetails.patientProfileDetails?.contactInfo?.mobile,
                    [AnalyticsConstant.PROFILE_EMAIL]: slotBookingDetails.patientProfileDetails?.contactInfo?.email,
                    [AnalyticsConstant.EVENT_SUB_TYPE]: slotBookingDetails.bookingSubType,
                    [AnalyticsConstant.SLOT_DATE]: new Date(slotBookingDetails.slotDate).toLocaleDateString(),
                    [AnalyticsConstant.SLOT_TIME]: new Date(slotBookingDetails.slotTime).toLocaleTimeString(),
                    [AnalyticsConstant.APPLIED_DISCOUNT_PACKAGE_NAME]: slotBookingDetails.packageName ?? "",
                    [AnalyticsConstant.ORIGINAL_AMOUNT]: slotBookingDetails.payment?.originalAmount?.toString() ?? "",
                    [AnalyticsConstant.PACKAGE_DISCOUNT]: slotBookingDetails.payment?.packageDiscountAmount?.toString() ?? "",
                    [AnalyticsConstant.OTHER_DISCOUNT]: slotBookingDetails.payment?.otherDiscountAmount?.toString() ?? "",
                    [AnalyticsConstant.FINAL_AMOUNT]: slotBookingDetails.payment?.finalAmount?.toString() ?? "",
                    [AnalyticsConstant.TOTAL_AMOUNT]: totalAmountPaid ?? "",
                    [AnalyticsConstant.TRANSACTION_ID]: transactionId ?? "",
                    [AnalyticsConstant.APPOINTMENT_DATE_TIME]: new Date(slotBookingDetails.slotDate + slotBookingDetails.slotTime),
                    [AnalyticsConstant.FILTERED_SERVICES_FOR_GA4]: ServicelistforGa4 ?? "",
                };
            } else if (basketRequest && basketRequest.cartItemList && basketRequest.cartItemList.length > 0 && basketRequest.cartItemList[0] != null) {
                let cartItem = basketRequest.cartItemList[0];
                patientDetails = cartItem.patientProfileDetails;
                cartItem.basketDiscount.forEach(discObj => {
                    if (discObj.type == BasketDiscount.COUPON_DISCOUNT)
                        couponCode = discObj.name;
                });
                eventData = {
                    ["Order ID"]: cartItem.orderId,
                    ["Invoice ID"]: cartItem.invoiceId,
                    ["BaseInvoice ID"]: cartItem.baseInvoiceId,
                    [AnalyticsConstant.PROFILE_PHONE]: patientDetails?.contactInfo?.mobile,
                    [AnalyticsConstant.PROFILE_EMAIL]: patientDetails?.contactInfo?.email,
                    [AnalyticsConstant.CART_NAME]: cartItem.productList && cartItem.productList.length > 0 ? (cartItem.productList?.flatMap(x => [x.productName]).join(",")) : (cartItem.pharmacyList && cartItem.pharmacyList.length > 0 ? (cartItem.pharmacyList?.flatMap(x => [x.productName]).join(",")) : ''),
                    [AnalyticsConstant.APPLIED_DISCOUNT_PACKAGE_NAME]: cartItem.packageName,
                    [AnalyticsConstant.ORIGINAL_AMOUNT]: cartItem.payment?.originalAmount?.toString() ?? "",
                    [AnalyticsConstant.PACKAGE_DISCOUNT]: cartItem.payment?.packageDiscountAmount?.toString() ?? "",
                    [AnalyticsConstant.OTHER_DISCOUNT]: cartItem.payment?.otherDiscountAmount?.toString() ?? "",
                    [AnalyticsConstant.FINAL_AMOUNT]: cartItem.payment?.finalAmount?.toString() ?? ""
                }
            }

            if (eventData && Config.portal?.enableClevertap) {
                (window as any).clevertap?.event?.push(event, eventData);
            }
            if (eventData && Config.portal?.enableMoengage) {
                (window as any).Moengage?.track_event(event, eventData);
            }
            if (eventData && Config.portal?.enableGTM && isBookingSourceConsumerPortal) {
                if (event == AnalyticsConstant.DIAGNOSTIC_ORDER_PLACED) {
                    (window as any).dataLayer?.push({
                        event: AnalyticsConstant.BEGIN_CHECKOUT,
                        ecommerce: {
                            currency: "INR",
                            value: eventData[AnalyticsConstant.TOTAL_AMOUNT],
                            items: eventData[AnalyticsConstant.FILTERED_SERVICES_FOR_GA4].map((service, index) =>

                            ({
                                item_id: service.serviceId,
                                item_name: service.serviceName,
                                index: index,
                                price: service.finalAmount,
                                quantity: service.quantity
                            })
                            )
                        }
                    })
                } else if (event == AnalyticsConstant.ACTION_DIAGNOSTIC_PAYMENT_SUCCESSFUL) {
                    (window as any).dataLayer?.push({
                        event: AnalyticsConstant.PURCHASE,
                        ecommerce: {
                            transaction_id: eventData[AnalyticsConstant.TRANSACTION_ID],
                            value: eventData[AnalyticsConstant.TOTAL_AMOUNT],
                            currency: "INR",
                            items: eventData[AnalyticsConstant.FILTERED_SERVICES_FOR_GA4].map((service, index) =>

                            ({
                                item_id: service.serviceId,
                                item_name: service.serviceName,
                                index: index,
                                price: service.finalAmount,
                                quantity: service.quantity
                            })
                            )

                        }
                    });
                }
            }
            if (eventData && Config.TEST_TYPE >= Config.LIVE && Config.portal?.enablePixel) {
                this.sendPixelEvent(event, eventData);
            }
            if (eventData && Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableNetcore) {
                this.sendNetcoreEvent(event, eventData);
                (<any>window).smartech('identify', patientDetails?.contactInfo?.mobile);

                this.updateNetcoreUserInfo(patientDetails);
            }
        }
    }

    public static customEventTrigger(eventData: any, event) {
        if (eventData && Config.portal?.enableClevertap) {
            (window as any).clevertap?.event?.push(event, eventData);
        }
        if (eventData && Config.portal?.enableMoengage) {
            (window as any).Moengage?.track_event(event, eventData);
        }
        if (eventData && Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableGTM) {
            // ReactGA.event({
            //     category: 'Page',
            //     action: 'onPageClick',
            //     label: 'My Button Click',
            //     value: 99,
            //   });
            
            if (event == AnalyticsConstant.DIAGNOSTIC_ADD_CART) {
                // (window as any).gtag("event", "add_to_cart", eventData);
                (window as any).dataLayer?.push({
                    event: AnalyticsConstant.ADD_TO_CARD,
                    ecommerce: {
                        currency: "INR",
                        value: eventData.price,
                        items: [
                            {
                                item_id: eventData[AnalyticsConstant.LABEL_ID],
                                item_name: eventData[AnalyticsConstant.LABEL_NAME],
                                price: eventData.price,
                                quantity: 1
                            }
                        ]
                    }
                });

            } else if (event == AnalyticsConstant.DIAGNOSTIC_TEST_DETAILS) {
                (window as any).dataLayer?.push({
                    event: AnalyticsConstant.VIEW_ITEM,
                    ecommerce: {
                        items: [
                            {
                                item_id: eventData[AnalyticsConstant.LABEL_ID],
                                item_name: eventData[AnalyticsConstant.LABEL_NAME],
                            }
                        ]
                    }   
                });
            }
        }
        if (eventData && Config.TEST_TYPE >= Config.LIVE && Config.portal?.enablePixel) {
            this.sendPixelEvent(event, eventData);
        }
        if (eventData && Config.TEST_TYPE >= Config.LIVE && Config.portal?.enableNetcore) {
            this.sendNetcoreEvent(event, eventData);
        }
    }

}