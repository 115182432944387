import { AppConstants } from './appconstants';
import { Config } from './config';
const POZ = AppConstants.BASE_URLS[Config.TEST_TYPE][AppConstants.POZ_BASE_URL_INDEX];
const HS = AppConstants.BASE_URLS[Config.TEST_TYPE][AppConstants.CONQUEST_BASE_URL_INDEX];
const EL = AppConstants.BASE_URLS[Config.TEST_TYPE][AppConstants.ELASTIC_URL_INDEX];
const CE = AppConstants.BASE_URLS[Config.TEST_TYPE][AppConstants.CHAT_URL_INDEX];


export const paths = Object.freeze({
    // Socket
    'NOTIFICATION_SOCKET': `${CE}notification`,
    'CHAT_SOCKET': `${CE}chat`,
    'VIDEO_PRESCRIPTION_SOCKET': `${CE}videoprescription`,
    'PHLEBO_LOCATION_TRACE_SOCKET': `${CE}phlebotomist`,
    // Login
    // 'GENERATE_OTP_FOR_LOGIN': `${HS}v2/account/otp`,
    // 'LOGOUT': `${HS}v2/account/logout`,//?profileId=<>&deviceToken=<>
    // "GENERATE_OTP_FOR_ORDERID": `${HS}v2/account/otp/order`,//orderId={0}
    // 'OTP_LOGIN': `${HS}v2/account/login`,
    // 'GENERATE_OTP_FOR_EMAIL': `${HS}v2/account/emailotp`,
    // 'EMAIL_OTP_LOGIN': `${HS}v2/account/loginemailotp`,
    'GENERATE_OTP_FOR_LOGIN': `${HS}account/loginOTP`,
    'LOGOUT': `${HS}account/logout`,//?profileId=<>&deviceToken=<>
    "GENERATE_OTP_FOR_ORDERID": `${HS}account/sendOtpforconsumer`,//orderId={0}
    'OTP_LOGIN': `${HS}account/loginthroughOTP`,
    'GENERATE_OTP_FOR_EMAIL': `${HS}account/emailotp`,
    'EMAIL_OTP_LOGIN': `${HS}account/loginemailotp`,
    'UPDATE_PRIMARY_USER': `${HS}account/registerPrimaryUser`,
    //Registration
    'UPDATE_PROFILE': `${HS}userprofile/secure/updateprofile`,
    'USER_SEARCH': `${HS}userprofile/secure/profiles`,
    'ADD_NEW_FAMILY_PROFILE': `${HS}userprofile/secure/savefamilydetails`,
    'REGISTRATION_WITH_OTP': `${HS}account/registration`,
    'GET_CITY_NAMES': `${EL}getcitynamesuggestion`,
    //Delete Account 
    'Get_OTP_FOR_ACCOUNT_DELETION': `${HS}account/secure/deactivationOTP`,
    'DELETE_USER_ACCOUNT': `${HS}account/secure/deleteaccount`,
    //Package 
    'PACKAGE_LIST': `${HS}hspackage/userpackageslist`,//profileId={0}&brandId={1}&packageSubType={2}
    'PACKAGE_DETAILS': `${POZ}hspackage/getpackagebasedonpackageid`,//?packageId={0}
    'DOCTOR_SPECIALIST_LIST': `${POZ}doctorappcontrol/getpackagedoctorsbydistance`,//latitude={0}&longitude={2}&serviceId={3}&packageId={4}
    //Diagnostic
    'DIAGNOSTIC_WALKIN_TEST_LIST': `${POZ}investigationcontrol/tests`,
    // 'FETCH_ADVERTISEMENT_LIST': `${HS}dashboard/getadvertisementlist`,
    'FETCH_ADVERTISEMENT_LIST': `${HS}dashboard/portal/home`,//bookingType=3&bookingSubType=2&pinCode=560034
    'DIAGNOSTIC_MOST_BOOKED_WALKIN_TESTS': `${POZ}investigationcontrol/populartests`,//?homeCollections=false&pocId=0&from=0&pinCode=560102&size=10`,
    'DIAGNOSTIC_WALKIN_TEST_CATEGORIES': `${POZ}investigationcontrol/getdiagnosticscategory`,//?homeCollections=false`,
    'DIAGNOSTIC_PACKAGES_OR_PROFILES': `${POZ}investigationcontrol/packagetests`,//?pocId=0&pinCode=560102&from=0&category=4&size=10&homeCollections=false`,
    'PACKAGE_TEST_DETAILS': `${POZ}investigationcontrol/packagetestdetails`,//?serviceId=12015&pinCode=560102&homeCollections=false&pocId=0&category=1 
    'GET_DIAGNOSTIC_POC_LIST': `${POZ}consumercontroller/diagnosticpoc`,
    // 'DIAGNOSTIC_SEARCH_TEST': `${POZ}investigationcontrol/tests`,
    'GET_SERVICE_LIST_BASEDON_POC': `${EL}getservicelistbasedonpocid`,
    'GET_DIAGNOSTIC_WALKIN_SLOT': `${POZ}investigationcontrol/secure/walkindiagnosticslots`,
    'GET_DIAGNOSTIC_HOMECOLLECTION_SLOT': `${POZ}investigationcontrol/secure/homediagnosticslots`,
    'RAISE_ORDER_REQUEST': `${POZ}investigationcontrol/secure/uploadprescriptionrequest`,
    'GET_TEST_SUGGESTIONS': `${POZ}investigationcontrol/gettestsuggestions`,
    'SPLIT_ORDER': `${POZ}basketcontroller/secure/splitorders`,
    //DIAGNOSTICCENTRES
    'GET_DIAGNOSTIC_CENTRES_LIST': `${EL}getpocbasedonlocation`,//?searchTerm={0}&lat={1}&lon={2}&brandId={3}&state={4}&city={5}&area={6};
    'GET_DIAGNOSTIC_CENTRE_DETAILS': `${POZ}poccontrol/poccenterdetails`,//pocId=0`
    // 'GET_GOOGLE_REVIEWS_FOR_POC': `${POZ}commoncontroller/getpocgooglereviews`,//pocId=0
    //Checkout
    'GET_BOOKED_PACKAGES': `${HS}hspackage/secure/getbookedpackages`,//doctorId=0&profileId=21487&serviceId=10076&slotDate=1581013800000&brandId=0&pocId=185&discountType=3
    'INITIATE_PAYMENT': `${POZ}basketcontroller/secure/getpaymentprovidesdetails`,
    "GET_BASKET_DETAILS": `${POZ}basketcontroller/secure/getorderdetails`,
    'GET_VIDEO_BOOKING_QUESTIONS': `${POZ}basketcontroller/secure/getvideobookingquestion`,
    'UPDATE_VIDEO_BOOKING_QUESTIONS': `${POZ}basketcontroller/secure/updatequestionansforvideobooking`,
    'CALCULATE_BASKET': `${POZ}basketcontroller/secure/calculateBasket`,
    'GET_WALLET_AMOUNT': `${POZ}hspackage/secure/getwalletamount`,
    'GET_PC_DISC_AMOUNT': `${POZ}vdc/secure/calculatePrivilegeDiscount`,
    //Location
    'GET_LOC_BY_LATLON': `${EL}getlocationbylatandlon`,
    'GET_SEARCHED_ADDRESS': `${EL}getlocationscompletionsuggester`,
    'GET_STATE_CITY_BY_PINCODE': `${HS}userprofile/getstatecityusingpincode`,
    'UPDATEADDRESS': `${HS}userprofile/secure/updateaddress`,
    //
    'GET_TEMP_FILE_URL_FROM_UUID': `${POZ}consumercontroller/secure/file`,
    'GET_TEMP_FILE_URL_FROM_SECURE': `${POZ}consumercontroller/secure/gettemporaryurls`,
    'GET_TEMP_UPLOAD_FILE_URL_FROM_SECURE': `${POZ}consumercontroller/secure/upload`,

    //Banner
    'RECORD_PACKAGE_INTEREST': `${POZ}consumercontroller/lead`,
    'RECORD_CAREERS': `${POZ}vdc/careers`,




    // Doctor Booking
    'DOCTOR_SERVICES': `${HS}mCommerce/servicecategory`, // ?pinCode=500064&brandId=0&videoDoctorsOnly=true&pocId=0 
    'DOCTOR_POPULAR_SERVICES': `${HS}mCommerce/getpopulardoctorservices`, // ?from=0&pinCode=500064&size=10&videoLaterConsultations=true 
    'GET_DOCTOR_DETAILS': `${POZ}schedulecontrol/getpocsofdoctor`,//?pocId=699&serviceId=211&doctorId=1353`,
    'PATIENT_SLOTS_OF_DOC': `${POZ}schedulecontrol/getpatientslotsofdoc`, //?pocId=699&serviceId=211&doctorId=1353
    'GET_DOCTOR_LIST': `${POZ}consumercontroller/getdoctorlist`,
    'DOCTOR_SEARCH': `${EL}autocompletefordoctor`,
    'DOCTOR_DETAILS': `${POZ}doctorappcontrol/getdoctordetails`, //?doctorId=<>&serviceId=<>
    'DOCTOR_PACKAGES': `${HS}hspackage/getpackagelistbasedondoctorservice`, //?brandId=0&doctorId=1006&serviceId=211&pocId=0
    'GET_LANGUAGES': `${HS}userprofile/getlanguages`,
    'LIVENOW_DOCTORS_LIST': `${POZ}consumercontroller/getlistoflivedoctors`,
    // Layout
    'UPDATE_GCM_TOKEN': `${HS}userprofile/secure/gcmregister`,
    // Video
    'GET_SESSION_FOR_PATIENT': `${POZ}consumercontroller/secure/getsessionforapatient`,//profileId,orderId
    'RESEND_VIDEO_NOTIFICATION': `${POZ}doctorappcontrol/secure/resendvideonotification`,//?invoiceId=%s&source=%d',

    //Health Locker
    // 'GET_PHR_REPORTS': `${HS}v2/phr/report/secure/getreports`,
    'GET_PHR_REPORTS': `${HS}phr/report/secure/getreports`,
    'SEARCH_TEST_FOR_REPORT': `${POZ}investigationcontrol/getinvestigationlist`,
    // 'UPLOAD_INVASIVE': `${HS}v2/phr/report/secure/updateinvasive`,
    // 'UPLOAD_FILE': `${HS}v2/phr/report/secure/updatefileurl`,
    'UPLOAD_INVASIVE': `${HS}phr/report/secure/updateinvasive`,
    'UPLOAD_FILE': `${HS}phr/report/secure/updatefileurl`,

    //consultation
    'UPCOMMING_CONSULTATION': `${POZ}basketcontroller/secure/upcomingeventforuser`,
    'PAST_CONSULTATION': `${POZ}consumercontroller/secure/getmydoctorvisitconsultationdetails`,
    'CANCEL_CONSULTATION': `${POZ}basketcontroller/secure/cancellingpayment`,
    'CHECK_CANCEL_CONSULTATION': `${POZ}basketcontroller/secure/checkcancellingpayment`,

    //consultation reports
    // 'GET_MYCONSULTATION_VITAL': `${HS}v2/phr/secure/phrinvasivenoninvasive`,//?profileId=118020&parentProfileId=114193'
    // 'GET_MYCONSULTATION_REPORTS': `${HS}v2/phr/report/secure/getreports`, //profileId=118020&parentProfileId=114193&type=0,1,5
    'GET_MYCONSULTATION_VITAL': `${HS}phr/secure/phrinvasivenoninvasive`,//?profileId=118020&parentProfileId=114193'
    'GET_MYCONSULTATION_REPORTS': `${HS}phr/report/secure/getreports`, //profileId=118020&parentProfileId=114193&type=0,1,5
    'GET_FAMILY_DETAILS': `${HS}userprofile/secure/getfamilydetails`,//?profileId=114043&parentProfileId=114043
    'GET_REPORT_GRAPH': `${HS}phr/report/secure`, //?id=10270&fromDate=1575190806443&toDate=1592038806443&parentProfileId=114139
    // 'UPDATE_VITALS': `${HS}v2/phr/secure/updatetest`,
    'UPDATE_VITALS': `${HS}phr/secure/updatetest`,

    //Edit PHR
    'GET_PHR_DETAILS': `${HS}phr/secure/getphrdetails`,
    'SUBMIT_PHR_DETAILS': `${HS}phr/secure/submitphrdetails`,

    //Profile
    'GET_PROFILE_DETAILS_BY_ID': `${HS}userprofile/secure/getfullprofiledetails`,
    'UPDATE_LANGUAGES': `${HS}userprofile/secure/updatelanguages`,
    'UPDATE_ADDRESS': `${HS}userprofile/secure/updateaddress`,
    'GET_INTEREST_LIST': `${HS}healthsocial/categorylist`,
    'SET_INTEREST_LIST': `${HS}userprofile/secure/setareaofinterest`,
    'UPDATE_PROFILE_PIC': `${POZ}commoncontroller/secure/updateimageurl`,

    //Main Dashboard
    'GET_DATA_WHEN_LOGGED_IN': `${HS}dashboard/secure/v2/dashboard`,
    'GET_DATA_WHEN_LOGGED_OUT': `${HS}dashboard/v2/dashboard`,
    'CHECK_ACTIVE_PATIENT_SESSION': `${POZ}consumercontroller/secure/getsessionforapatient`,//?profileId=114141`
    'GET_FREQUENT_PURCHASES': `${POZ}consumercontroller/frequentpurchases`,
    'GET_USER_METRICS': `${HS}dashboard/secure/user/metrics`,
    'GLOBAL_SEARCH': `${EL}globalsearch`,
    'RECENT_PRESCRIPTIONS': `${HS}advice/secure/pharmacy?patientProfileId=0&onlyPharmacy=false&daysLimit=7&from=0&size=1&parentProfileId=`,
    'SELLING_PHARMACY_PRICE': `${HS}symptomadvice/secure/getmedicinesdetailsbasedonstatecities`,
    'GET_UPCOMING_CONSULTATION': `${POZ}basketcontroller/secure/upcomingeventforuser`,
    'UPDATE_VIDEO_SOURCE': `${POZ}consumercontroller/secure/videosource?orderId=`,

    //app in app Dashboard
    'GET_SPECIFIC_POC_DATA': `${HS}dashboard/poc/home`,

    //Pharmacy Dashboard
    'PHARMACY_AUTO_COMPLETE': `${EL}pharmacyautocomplete`,
    'GET_PRODUCT_DETAILS_BY_ID': `${POZ}pharmacy/getproductdetailsbyid`,//?productIds=240213,213089
    'PURCHASE_MEDICINES': `${HS}mCommerce/secure/purchasemedicines`,
    'GET_TOP_SELLING_MEDICINES': `${POZ}pharmacy/gettopsellingpharmacy`,//?categoryId=0&from=0&size=20

    //Profile Details
    'GET_PROFILE_ADDRESS': `${HS}userprofile/secure/getaddresses`,
    'GET_PROFILES': `${HS}dashboard/secure/getdashboard`,

    //Product DashboardItem
    'GET_PRODUCT_CATEHORIES': `${POZ}product/getproductcategory`,
    'GET_TOP_SELLING_PRODUCTS': `${POZ}product/gettopsellingproducts`,
    'GET_PRODUCT_DETAILS': `${POZ}product/getlistproductdetails`,
    'PRODUCT_AUTO_COMPLETE': `${EL}productautocomplete`,
    'GET_PRESCRIBED_STATUS': `${POZ}pharmacy/getdrugisprescribedornot`,
    'GET_PRODUCT_BANNERS': `${HS}dashboard/portal/home`,//?pinCode=560075&cartItemType=6`

    // Consent Management
    'CONSENT_REQUEST_URL': `${HS}consent/secure/consents`,
    'UPDATE_CONSENT_URL': `${HS}consent/secure/approve/consent`,

    //Order Listing
    'ORDER_LIST': `${POZ}basketcontroller/secure/getorderslist`,
    'GET_NOTIFICATIONS': `${HS}calendar/secure/getevents`,//?profileId=<>&startDate=<>&endDate=<>&scrollDirection=<>&offset=<>`,
    'GET_ORDER_DETAILS_FOR_DIAGNOSTIC_ORDERS': `${POZ}investigationcontrol/secure/getdiagnostichomeorderfororderid`,//?orderId=%s",
    'GET_ORDER_DETAILS_FOR_PRODUCT': `${POZ}pharmacydelivery/getorderdetails`,//orderId=%s",
    'GET_PHLEBO_ROUTE': `${POZ}consumercontroller/secure/phleboroute`,// ?parentProfileId&origin=<>&destination=<>
    //wellness
    'GET_WELLNESS_CATEGORIES': `${POZ}wellnesscontroller/getwellnesscategories`,//?pocId=0
    'GET_WELLNESS_POC': `${POZ}wellnesscontroller/wellnesspoc`,
    'GET_WELLNESS_POPULAR_SERVICES': `${POZ}wellnesscontroller/popularservices`,//?pocId=1471&from=0&size=10
    'GET_WELLNESS_SERVICES': `${POZ}wellnesscontroller/wellnessservices`,
    'GET_WELLNESS_SLOTS': `${POZ}wellnesscontroller/walkinwellnessslots`,//?pocId=1174&scheduleId=0&serviceIdList=1017

    //FAQs
    'GET_FAQ_CATEGORIES': `${HS}faq/categories`,
    'GET_FAQS_WITH_CATEGORY_ID': `${HS}faq/questions`,//faq/questions?categoryId=1
    'GET_FAQS_WITH_SEARCH_AND_CAT_ID': `${HS}faq/search0`,//faq/search?query=acne&categoryId=1

    // Rating and Review
    'SET_USER_RATING': `${POZ}userratingcontroller/userrating`,
    'GET_RATING_DETAILS': `${POZ}userratingcontroller/displayreview`,//?profileId=118135

    // Healthtips
    'GET_HEALTH_TIP_DETAILS': `${HS}healthtips/gethealthtipbasedonid`,//?healthTipId=324

    // privilegecard
    'UPDATE_PRIVILEGE_DETAILS': `${POZ}basketcontroller/secure/updateuserprivilegedetails`,
    'REMOVE_PRIVILEGE_DETAILS': `${POZ}basketcontroller/removeuserprivilegedetails`,
    'UPDATE_PRIVILEGE_CARD_MEM': `${POZ}basketcontroller/addextraprivilegefamilymembers`,
    'GET_PROFILES_WITH_PARENT_PROFI_ID': `${POZ}basketcontroller/getallprofilesbasedonparentprofileid`,//?parentProfileId= 460000998
    'GET_PRIVILEGE_CARD_BENEFITS': `${POZ}basketcontroller/getuserprivilegedetails`,//?parentProfileId=460000998&patientProfileId=460000998

    //urls for getting .states and cities
    'GET_REGIONS': `${POZ}poccontrol/getregions`,
    'UPDATE_CURRENT_AND_FUTURE_JOB_OPENINGS': `${POZ}consumercontroller/currentandfuturejobopenings`,
    'GET_SERVICEABLE_REGIONS': `${POZ}poccontrol/getserviceableregions`,

    //My dashboard
    'GET_PHLEBO_ORDER_IN_PROGRESS': `${POZ}basketcontroller/secure/getorderonphlebostarted`,//?profileId=114208,
    'UPCOMING_TEST_NOTIFICATION': `${HS}vdc/secure/getDueTestsOfUser`,

    // Wallet History
    'GET_WALLET_HISTORY_LIST': `${POZ}hspackage/secure/getwallethistory`,//?profileId=%s&from=0&size=100`,
    'GET_POST_WALLET_ORDER_LIST': `${POZ}basketcontroller/secure/viewgeneratepostwalletbill?profileId=%s`,

    // Map Token
    'GET_MAP_TOKEN': `${POZ}commoncontroller/getmaptoken`

});
