export class ReduxAuth {
    private static getState = (): any => { };
    private static dispatchState = (a: any): any => { };
    public static setAuthState(_state) {
        this.getState = _state;
    }
    public static setDispatchState(_dispatchState) {
        this.dispatchState = _dispatchState;
    }
    public static getAuthState() {
        return this.getState();
    }
    public static getDispatchState(params) {
        return this.dispatchState(params);
    }
}