import AxiosService from '../../base/portal_env_config/axiosService';
import { paths } from '../../base/portal_env_config/urlconstants';
import { LoginDetails } from '../../model/login/loginDetails';
import { OTPRequest } from '../../model/login/OTPRequest';

export class LoginService {
    public static async loginPortal(loginDetails: LoginDetails) {
        return AxiosService.httpPost(paths.OTP_LOGIN, loginDetails).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
                // throw(err);
            }
        });
    }

    public static async loginPortalWithEmail(loginDetails: LoginDetails) {
        return AxiosService.httpPost(paths.EMAIL_OTP_LOGIN, loginDetails).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
                // throw(err);
            }
        });
    }

    public static async generateOTPForLogin(otpRequest: OTPRequest) {
        return AxiosService.httpPost(paths.GENERATE_OTP_FOR_LOGIN, otpRequest).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async generateOTPForEmailLogin(otpRequest: OTPRequest) {
        return AxiosService.httpPost(paths.GENERATE_OTP_FOR_EMAIL, otpRequest).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async logout(req) {
        return AxiosService.httpGet(paths.LOGOUT, req).then((res) => {
            return Promise.resolve({});
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }
}