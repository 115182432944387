export default class AnalyticsConstant {

    public static DATA_NOT_FOUND = 404;

    public static PLATFORM = "Platform";

    public static EVENT_TYPE = "Event Type";
    public static EVENT_SUB_TYPE = "Event SubType";

    public static ORDER_ID = "Order ID";
    public static TRANSACTION_ID = "transactionId";
    public static LABEL_ID = "Label Id";
    public static LABEL_NAME = "Label Name";
    public static ACTION_TIME = "Action Time";
    public static PRICE = "price";

    public static ACTION_VIEW = "View Page";
    public static ACTION_PAID = "Paid Done";
    public static ACTION_BUTTON = "Button Click";

    public static PROFILE_ID = "Identity";
    public static PROFILE_NAME = "Name";
    public static PROFILE_AGE = "Age";
    public static PROFILE_GENDER = "Gender";
    public static PROFILE_PHR = "PHR";
    public static PROFILE_PHONE = "Phone";
    public static PROFILE_EMAIL = "Email";

    public static PATIENT_ID = "patient id";
    public static PATIENT_NAME = "patient name";

    public static DOCTOR_NAME = "Doctor Name";
    public static POC_ID = "Poc Id";
    public static POC_NAME = "Poc Name";
    public static SERVICE_NAME = "Service Name";
    public static TEST_NAME = "Test Name";
    public static SLOT_DATE = "Slot Date";
    public static SLOT_TIME = "Slot Time";
    public static APPOINTMENT_DATE_TIME = "Appointment Date Time";

    public static DIAGNOSTIC_TEST_DETAILS_SCREEN = "Diagnostic Details Page";
    public static BUY_DIAGNOSTIC_TEST = "But Diagnostic";

    public static CART_NAME = "CART_NAME";
    public static APPLIED_DISCOUNT_PACKAGE_NAME = "Discount Package Name";
    public static ORIGINAL_AMOUNT = "Original Amount";
    public static PACKAGE_DISCOUNT = "Package DIscount";
    public static OTHER_DISCOUNT = "Other Discount";
    public static FINAL_AMOUNT = "Final Amount";
    public static TOTAL_AMOUNT = "Total Amount";
    public static PAYMENT_PAID = "Payment Paid";
    public static CHARGED = "Charged ";
    //for campaign
    public static ACTION_CAMPAIGN = "Campaign Click";
    //for login screen
    public static LOGIN_SCREEN = "Login Page";
    public static ACTION_LOGIN_CLICK = "Login Click";
    public static ACTION_LOGIN_SUCCESS = "Login Success";
    public static REGISTER_WITH_OTP = "Register with OTP";
    public static LOGIN_FAILED = "Login Failed";
    public static OTP_FAILED = "OTP Failed";
    //for logout
    public static ACTION_LOGOUT_CLICK = "LogOut Click";

    public static ITEM_SELECTION = "Item Selection";
    public static ITEM_CHECKED = "Item Selected";
    public static ITEM_UNCHECKED = "Item Unselected";

    //for dashboard
    public static DASHBOARD = "Dashboard Page";
    public static DASHBOARD_ITEM_CLICK = "Item Click";
    public static ACTION_DASHBOARD_ITEM_CONSULTATION = "MyConsultation Click";
    public static ACTION_DASHBOARD_ITEM_PACKAGE_HISTORY = "MyPackage Click";
    public static ACTION_DASHBOARD_ITEM_REPORTS = "My Report Click";
    public static ACTION_DASHBOARD_ITEM_HEALTH_REPORT = "Health report Click";

    public static FEATURE_SELECTION_PAGE = "Feature Selection Page";
    public static ACTION_SUBMIT_FEATURE_SELECTION = "Feature Submit";

    //PHR
    public static MANAGE_BODY_SCREEN = "Manage Body Page";
    public static KNOW_YOUR_BODY_SCREEN = "Know MyBody Page";
    public static ACTION_KNOW_YOUR_BODY_UPDATE_PHR = "PHR Update Click";
    public static ACTION_KNOW_YOUR_BODY_HEALTH_SCREENING = "Health Screening Click";

    public static ACTION_EDIT_SURGICAL_HISTORY = "Surgical History Click";
    public static ACTION_EDIT_TREATMENT = "Treatment Click";
    public static ACTION_EDIT_LIFESTYLE = "LifeStyle Click";

    public static ACTION_ADD_FAMILY_MEMBER = "Add Family Member";

    public static ACTION_SUBMIT_PHR = "PHR submit";

    public static HEALTH_TIPS_SCREEN = "Tips Page";
    public static ACTION_HEALTH_TIPS_PLAY = "Tips Item Play";

    public static DASHBOARD_HS_CALENDAR = "Calendar Page";
    public static CALENDAR_DAY_SCREEN = "Calendar Day Page";
    public static CALENDAR_3DAY_SCREEN = "Calendar 3Day Page";
    public static CALENDAR_7DAY_SCREEN = "Calendar 7Day Page";
    public static CALENDAR_DETAILS_SCREEN = "Calendar Details";

    public static ADVISE_LIST_SCREEN = "Advise Page";
    // public static [] ADVISE_STATUS_SCREEN = { "New Advice Page", "Active Advice Page", "InActive advice Page"};

    public static DIET_GENERATION_SCREEN = "Diet Generation Page";
    public static DIET_DETAILS_SCREEN = "Diet Details Page";
    public static DIET_MODIFY_SCREEN = "Diet Modify Page";

    public static PHYSICAL_GENERATION_SCREEN = "Physical Generation Page";
    public static PHYSICAL_DETAILS_SCREEN = "Physical Details Page";
    public static PHYSICAL_EXERCISE_LIST_SCREEN = "Exercise Page";

    public static ACTION_EXERCISE_CLICK_TO_DOWNLOAD = "Download Exercise";
    public static ACTION_EXERCISE_STREAMING_VIDEO_SCREEN = "Streaming Exercise";
    public static ACTION_EXERCISE_DOWNLOADED_VIDEO_SCREEN = "Downloaded Exercise";

    public static ACTION_ADVICE_TAKEN = "Advice Taken";
    public static ACTION_ADVICE_NOT_TAKEN = "Advice Not Taken";
    public static ACTION_START_EXERCISE_VIDEO = "STart Exercise";
    public static ACTION_MODIFY_MEAL = "Modify Meal";

    public static ACTION_MAKE_CALL = "Make Call";
    public static ACTION_VIEW_MAP = "View Map";

    public static ACTION_REJECT_ADVICE = "Reject Advice";
    public static ACTION_ACCEPT_ADVICE = "Accept Advice";
    public static ACTION_DISCONTINUE_ADVICE = "Discontinue Advice";

    public static NOTIFICATION_SCREEN = "Notification Page";

    //My Profile History
    public static MY_HISTORY_DASHBOARD = "My Profile Page";

    //Profile
    public static MY_PROFILE_VIEW_SCREEN = "View Profile";
    public static MY_PROFILE_EDIT_SCREEN = "Edit Profile";
    public static SUBMIT_PROFILE = "Submit profile";

    //My Family
    public static ACTION_EDIT_FAMILY_HISTORY = "Edit Family History";
    public static MY_PROFILE_FAMILY = "View Family";
    public static MY_PROFILE_FAMILY_EDIT = "Edit Family";

    public static MY_IMMUNIZATION = "Immunization Page";
    public static SUBMIT_IMMUNIZATION = "Submit immunization";

    public static MY_TEST_INVASIVE = "View Invasive";
    public static MY_TEST_HISTORY = "View Test History";
    public static ACTION_BOOK_FROM_TEST = "Book From Test History";
    public static MY_CONSULTATION_UPCOMING_SCREEN = "View Upcoming Consultation";
    public static MY_CONSULTATION_COMPLETED_SCREEN = "View Completed Consultation";
    public static ACTION_PRESCRIPTION_PHARMACY_CART = "View Prescription Cart";

    public static MY_PHR_VIEW = "View PHR";
    public static MY_PHR_EDIT = "Edit PHR";

    public static MY_PERSONAL_DOCTOR_LIST_SCREEN = "Personal Doctor Page";

    //Order
    public static MY_ORDER_DETAILS = "Order Details Page";
    public static MY_ORDER_PROGRESS_LIST = "Progress Order Page";
    public static MY_ORDER_COMPLETED_LIST = "Completed Order Page";
    public static MY_ORDER_CANCELLED_LIST = "Cancelled Order Page";
    public static MY_ORDER_SEEK_APPROVE = "Approve Order Page";

    public static DOCTOR_SEARCH_SCREEN = "Doctor Search";
    public static DOCTOR_SEARCH_EMPTY = "Doctor Search Empty";
    public static SPECIALIZATION_PAGE_SCREEN = "Specialization Page";
    public static AILMENT_SCREEN = "Ailment Page";
    public static DOCTOR_INPERSON_LIST = "InPerson Doctor";
    public static DOCTOR_CONNECT_LATER_LIST = "Connect later Doctor";
    public static DOCTOR_CONNECT_NOW = "Connect Now Doctor";
    public static DOCTOR_LIST_EMPTY = "Doctor Empty";
    public static DOCTOR_DETAILS = "Doctor Details";

    public static VIEW_DOCTOR_SLOT = "Doctor Slot Page";
    public static CHOOSE_DOCTOR_SLOT = "Choose Doctor Slot";

    public static EXPERT_LIST = "Expert Page";

    public static VIEW_WELLNESS_LIST = "Wellness Page";
    public static WELLNESS_DETAILS = "Wellness Details Page";
    public static WELLNESS_SLOT = "Wellness Slot Page";
    public static CONFIRM_WELLNESS_SLOT = "Choose Wellness Slot ";

    public static DIAGNOSTIC_PURCHASE_HOME_SCREEN = "Test Dashboard Page";
    public static DIAGNOSTIC_TEST_CATEGORY = "Test Category Page";
    public static DIAGNOSTIC_TEST_LIST_BY_CATEGORY = "Test By Category page";
    public static DIAGNOSTIC_PACKAGE_DETAILS = "Test Package Screen";
    public static DIAGNOSTIC_TEST_DETAILS = "Test Details Screen";
    public static DIAGNOSTIC_ADD_CART ="Diagnostic Add Cart";


    public static DIAGNOSTIC_ACTION_SEARCH = "Diagnostic Search Click";
    public static DIAGNOSTIC_SEARCH_SCREEN = "Diagnostic Search Page";
    public static DIAGNOSTIC_SEARCH_EMPTY = "Diagnostic Search Empty";
    public static DIAGNOSTIC_POC_SPECIFIC_SEARCH_SCREEN = "Diagnostic POC Search Page";
    public static DIAGNOSTIC_LIST = "Diagnostic Listing Page";
    public static DIAGNOSTIC_LIST_EMPTY = "Diagnostic Empty";
    public static DIAGNOSTIC_DETAILS = "DIagnostic Details";

    public static DIAGNOSTIC_WALKIN_SLOT = "Diagnostic WalkIn Slot";
    public static DIAGNOSTIC_HOME_SLOT = "Diagnostic Home Slot";

    public static HOME_SERVICE_LIST = "HomeCare Page";

    public static PACKAGE_PURCHASE_HOME_SCREEN = "Package Page";
    public static PACKAGE_SEARCH_SCREEN = "Package Search Page";
    public static PACKAGE_SEARCH_EMPTY = "Package Search Empty";
    public static PACKAGE_HOME_CATEGORY_ITEM = "Package Category";
    public static PACKAGE_LIST_SCREEN = "Package Listing Page";
    public static PACKAGE_DETAILS = "Package Details";
    public static MY_PACKAGE_LIST_SCREEN = "My Package Listing";
    public static PACKAGE_UTILIZATION_SCREEN = "Package Utilization";

    public static DOCTOR_CONFIRMATION_SCREEN = "Doctor Confirmation Page";
    public static WELLNESS_CONFIRMATION_SCREEN = "Wellness Confirmation Page";
    public static DIAGNOSTIC_CONFIRMATION_SCREEN = "Diagnostic Confirmation Page";
    public static CONFIRM_PACKAGE_SCREEN = "Package Confirmation Page";
    public static CONFIRM_PRODUCT_SCREEN = "Product Confirmation Page";
    public static CONFIRM_PHARMACY_SCREEN = "Pharmacy Confirmation Page";
    public static CONFIRM_DIAGNOSTIC_SLOT = "Choose Diagnostic Slot";

    public static ACTION_DOCTOR_PURCHASE = "Doctor Purchase Click";
    public static ACTION_WELLNESS_PURCHASE = "Wellness Purchase Click";
    public static ACTION_DIAGNOSTIC_PURCHASE = "Diagnostic Purchase Click";
    public static ACTION_PACKAGE_PURCHASE = "Package Purchase Click";
    public static ACTION_PRODUCT_PURCHASE = "Product Purchase Click";
    public static ACTION_PHARMACY_PURCHASE = "Pharmacy Purchase Click";

    public static ACTION_DOCTOR_PAYMENT_CANCEL = "Doctor Payment Cancel";
    public static ACTION_DIAGNOSTIC_PAYMENT_CANCEL = "Diagnostic Payment Cancel";
    public static ACTION_WELLNESS_PAYMENT_CANCEL = "Wellness Payment Cancel";
    public static ACTION_PACKAGE_PAYMENT_CANCEL = "Package Payment Cancel";
    public static ACTION_PHARMACY_PAYMENT_CANCEL = "Pharmacy Payment Cancel";
    public static ACTION_PRODUCT_PAYMENT_CANCEL = "Product Payment Cancel";

    public static ACTION_DOCTOR_PAYMENT_SUCCESSFUL = "Doctor Payment Succeed";
    public static ACTION_DIAGNOSTIC_PAYMENT_SUCCESSFUL = "Diagnostic Payment Succeed";
    public static ACTION_WELLNESS_PAYMENT_SUCCESSFUL = "Wellness Payment Succeed";
    public static ACTION_PACKAGE_PAYMENT_SUCCESSFUL = "Package Payment Succeed";
    public static ACTION_PHARMACY_PAYMENT_SUCCESSFUL = "Pharmacy Payment Succeed";
    public static ACTION_PRODUCT_PAYMENT_SUCCESSFUL = "Product Payment Succeed";

    public static DOCTOR_ORDER_PLACED = "Doctor Order Placed";
    public static DIAGNOSTIC_ORDER_PLACED = "Diagnostic Order Placed";
    public static WELLNESS_ORDER_PLACED = "Wellness order Placed";
    public static PACKAGE_ORDER_PLACED = "Package Order PLaced";
    public static PHARMACY_ORDER_PLACED = "Pharmacy Order Placed";
    public static PRODUCT_ORDER_PLACED = "Product Order Placed";
    public static HOME_OR_WALKIN = "Collection Type";

    public static ACTION_ORDER_CANCEL = "Order Cancelled";
    public static LABEL_APPOINTMENT_CANCEL = "Appointment Cancelled";
    public static LABEL_WELLNESS_CANCEL = "Wellness Cancelled";
    public static LABEL_DIAGNOSTIC_CANCEL = "Diagnostic Cancelled";
    public static LABEL_PACKAGE_CANCEL = "Package Cancelled";

    public static CHAT_SCREEN = "Chat Page";
    public static LABEL_CHAT_VIDEO = "Vdeo";
    public static LABEL_CHAT_AUDIO = "Audio";
    public static LABEL_CHAT_MESSAGE = "Message";
    public static VALUE_CHAT = 2;

    public static VIDEO_QUALITY_CHECK_SCREEN = "VIdeo QUality Page";
    public static ACTION_VIDEO_QUALITY_SKIP_BUTTON = "Skip Video Quality";

    public static ACTION_START_VIDEO_CALL = "Start Video Call";
    public static ACTION_END_VIDEO_CALL = "End Video Call";
    public static ACTION_MUTE_AUDIO = "Mute Audio";
    public static ACTION_DISCONNECT_VIDEO_CALL = "DIsconnet Video";

    public static FINAL_CART_SCREEN = "Cart Page";

    public static EDIT_PHYSICAL_SCREEN = "Edit Physical";
    public static SUBMIT_PHYSICAL_SCREEN = "Submit Physical";

    public static GRAPH_VIEW_SCREEN = "Graph Page";
    public static PDF_VIEW_SCREEN = "PDF Page";
    public static IMAGE_VIEW_SCREEN = "Image page";

    public static ACTION_SHARE_FILE = "Share File";

    public static UPLOAD_FILE_SCREEN = "Upload Page";
    public static SUBMIT_UPLOAD_FILE_SCREEN = "Submit File";

    public static DOCTOR_ADVISE_SCREEN = "Doctor Prescription Screen";
    public static SYMPTOM_ADVISE_SCREEN = "Symptom Prescription Screen";
    public static PHARMACY_PURCHASE_SCREEN = "Pharmacy Purchase Screen";
    public static WELLNESSS_ACTION_SEARCH = "Wellness Search Click";

    //temp checkout
    public static TO_LOGIN_WITH_ORDER_INVOICE_ID = "Login Order Payment";
    public static TO_DASHBOARD_AS_NO_ORDERID = "Order Not Found";
    public static TO_CONFIRMATION_WITH_ORDERID_ZERO_AMOUNT_TO_PAY = "Zero Payment Confirmation";
    public static TO_LOGIN_AS_UNSUCCESFUL_BASK_REQ = "Order Not Found 2";

    //temp login
    public static ROUTER_BACK = "Router back";
    public static CHECKOUT_WITH_ORDERID_INCOICEID = "Checkout from Link";
    public static TO_DASHBOARD_FROM_LOGIN = "Login to Dashboard";

    // Add to cart Events
    public static PHARMACY_ADD_CART = "Pharmacy Cart";
    public static PRODUCT_ADD_CART = "Product Cart";
    public static DIAGNOSTICS_ADD_CART = "Diagnostics Cart";
    public static VIEW_PHARMACY_CART = "View Pharmacy Cart";
    public static VIEW_PRODUCT_CART = "View Product Cart";

    // Cart Attributes
    public static PRODUCT_NAME = "Product Name";
    public static PRODUCT_QUANTITY = "Product Quantity";
    public static PRODUCT_PRICE = "Product Price";

    // GA4 EVENTS

    public static BEGIN_CHECKOUT = "begin_checkout";
    public static PURCHASE = "purchase";
    public static VIEW_ITEM = "view_item";
    public static ADD_TO_CARD = "add_to_cart";
    public static FILTERED_SERVICES_FOR_GA4 = "filteredServicesforGa4";
    

}
