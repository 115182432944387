import { intervalToDuration } from "date-fns";
import { Address } from '../../model/profile/profileaddress';
import { Address as PocAddress } from '../../model/poc/address';

export default class CommonUtil {
    public static toString(obj: any) {
        if (!obj) return "";
        if (typeof obj === 'string')
            return obj;
        return JSON.stringify(obj)
    }
    public static hideText(text: string, fromIndex = 2, toIndex = 6): string {
        return text.replace(text.substr(fromIndex, toIndex), '*'.repeat(toIndex))
    }
    public static getAge(birthDate: Date) {
        const { years, months } = intervalToDuration({ start: birthDate, end: new Date() });
        return { years, months };
    }

    public static getAgeForall(dob: number): string {
        let age: string = '';
        let getAge = CommonUtil.getAge(new Date(dob));
        age = `${getAge.years} Years, ${getAge.months} Months`;
        return age;
    }
    
    public static getDobFromAge(years, months) {
        // console.log(months + "from dob-->" + years);
        var dob: number = 0;
        var date: Date = new Date();
        if (!years || years == 0) {
            //alert(months+"   "+(date.getMonth()+1));
            if (!months || months == 0) {
                var year = date.getFullYear();
            }
            else {
                var year = ((date.getFullYear() - 1) - years);
            }
            var month = (date.getMonth()) - months;
            if (month < date.getMonth()) {
                month = 12 + month;
            }
            date.setFullYear(year);
            date.setMonth(month);
            date.setHours(0, 0, 0, 0);
        } else {
            var year = (date.getFullYear() - 1) - years;
            var month = 12 - (months - date.getMonth());
            date.setFullYear(year);
            date.setMonth(month);
            date.setDate(1);
            date.setHours(0, 0, 0, 0);
        }
        dob = date.getTime();
        return dob;
    }
    public static getFormattedAddress(addressObj: Address) {
        //(addressObj?.landmark ? addressObj?.landmark + ", " : '') +
        // Removed landmark as we are getting fromatted address from map and appending in landmark
        return addressObj ? ((addressObj?.address1 ? addressObj?.address1 + ", " : '') +
            (addressObj?.address2 ? addressObj?.address2 + ", " : '') +
            (addressObj?.areaName ? addressObj.areaName + ', ' : '') +
            addressObj?.cityName + ", " + addressObj?.stateName + "-" + addressObj?.pinCode) : '';
    }
    public static getFormattedPOCAddress(addressObj: PocAddress) {
        return addressObj ? (addressObj?.address1 + ", " + addressObj?.address2 + ", "
            + (addressObj?.landmark ? addressObj?.landmark + ", " : '') + addressObj?.cityName + ", " + addressObj?.stateName + "-" + addressObj?.pinCode) : '';
    }
    public static convertDateToTimestamp(str) {
        str.setHours(0, 0, 0, 0);
        // console.log("Time stamp-->" + str);
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        var dateString = [day, mnth, date.getFullYear()].join("-");
        var newDate = new Date(dateString.split("-").reverse().join("-")).getTime();
        return newDate;
    }
    public static convertTimeToUTC(date) {
        let hour = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        let millisecond = date.getMilliseconds()
        let time = new Date(Date.UTC(1970, 0, 1, hour, minutes, seconds, millisecond)).getTime();
        return time;
    }
    public static convertDateToDayOfWeek(str) {
        return new Date(str).getDay();
    }

    public static getStringTimeFromTimeStampTo(timeslot: any) {
        var date = new Date(timeslot);
        var hours = date.getHours();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        var strhours = hours < 10 ? "0" + hours : hours;
        var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        var strTime = strhours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
    public static getDayOfWeek(index) {
        return ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"][index]
    }

    public static getFormattedName(title: string, firstName: string, lastName: string) {
        let fullName = (title ? title + ". " : "") + (firstName ? firstName : "") + (lastName ? (" " + lastName) : "");
        return fullName;
    }

    public static getDiscountPercentage(grossPrice, netPrice) {
        let discountPercentage = Math.round(((grossPrice - netPrice) / grossPrice) * 100);
        return discountPercentage;
    }

    public static setImageOrDefaultImg(imageUrl, defaultImg) {
        let image = "";
        if (imageUrl && imageUrl != "")
            image = imageUrl[0]?.split(".png")[0] + "hdpi.png"
        else if (!imageUrl || imageUrl == "") {
            image = defaultImg
        }
        return image;
    }

    //cleverTap purpose
    public static getYearOnlyAgeForall(dob: number): number {
        let age: number = 0;
        var date = new Date(dob);
        if ((date.getMonth() + 12) == 12) {
            var ageYears = (((new Date()).getFullYear()) - (date.getFullYear() + 1)) + 1;
            var ageMonths = 0;
        } else {
            var ageYears = (new Date()).getFullYear() - (date.getFullYear() + 1);
            if (ageYears == 0) {
                var ageMonths = 12 - (date.getMonth() - (new Date().getMonth() + 1));
            } else {
                var ageMonths = 12 - (date.getMonth() - new Date().getMonth());
            }
            if (ageMonths > 12) {
                ageYears = ((new Date()).getFullYear() - (date.getFullYear() + 1)) + 1;
                ageMonths = ageMonths - 12;
            }
        }

        age = ageYears;
        return age;
    }

    public static removeHTML(string) {
        var temp = document.createElement("p");
        temp.innerHTML = string;
        return temp.textContent || temp.innerText || "";
    }

    public static clone(data) {
        return { ...data }
    }

    public static convertToSlug(str) {
        if (str) {
            return str.toLowerCase()
                .replace(/ /g, '-')
                .replace(/[^\w-]+/g, '');
        } else return str;
    }

    public static removeLastWord(str) {
        const lastIndexOfSpace = str.lastIndexOf('-');
        if (lastIndexOfSpace === -1) {
            return str;
        }
        return str.substring(0, lastIndexOfSpace);
    }
}