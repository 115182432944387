"use strict";
var __assign =
    (this && this.__assign) ||
    function () {
        __assign =
            Object.assign ||
            function (t) {
                for (var s, i = 1, n = arguments.length; i < n; i++) {
                    s = arguments[i];
                    for (var p in s)
                        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
                }
                return t;
            };
        return __assign.apply(this, arguments);
    };
exports.__esModule = true;
const cities = require("./popularCitiesConst").cities;
var PortalConfig = /** @class */ (function () {
    function PortalConfig() {
    }
    PortalConfig.BASEDATA = {
        "defaultLoc": cities.Hyderabad,
        "storageHash": "v1.0",
        "version": "v1.0.1 (6th Feb 2023)",
        "timezoneDifferential": 19800000
        // "timezoneDifferential": 14400000
    };
    PortalConfig.VDC = __assign({}, PortalConfig.BASEDATA,
        require('../../../public/vdc/data/vdcConfig.json'),
        { "defaultLoc": cities.Hyderabad });
    PortalConfig.MYMEDIC = { appId: 0 };
    PortalConfig.CFH = { appId: 45 };
    return PortalConfig;
}());
exports["default"] = PortalConfig;
