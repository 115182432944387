
const cities = {
    Gulbarga: {
        "cityId": 344,
        "stateId": 24,
        "title": "Gulbarga",
        "pinCode": "585101",
        "locationCoordinates": {
            "lon": 76.8277309,
            "lat": 17.3276454
        },
        "slugName": "gulbarga"
    },
    Gurugram: {
        "cityId": 450,
        "stateId": 6,
        "title": "Gurugram",
        "pinCode": "122003",
        "locationCoordinates": {
            "lon": 77.0266,
            "lat": 28.4595
        },
        "slugName": "gurugram"
    },
    Hanmakonda: {
        "cityId": 865,
        "stateId": 23,
        "title": "Hanmakonda",
        "pinCode": "506001",
        "locationCoordinates": {
            "lon": 79.5584,
            "lat": 18.0072
        },
        "slugName": "hanmakonda",
    },
    Hyderabad: {
        "cityId": 825,
        "stateId": 23,
        "title": "Hyderabad",
        "pinCode": "500002",
        "locationCoordinates": {
            "lon": 78.4867,
            "lat": 17.3850
        },
        "slugName": "hyderabad"
    },
    Karimnagar: {
        "cityId": 4,
        "stateId": 23,
        "title": "Karimnagar",
        "pinCode": "505001",
        "locationCoordinates": {
            "lon": 79.1288,
            "lat": 18.4386
        },
        "slugName": "karimnagar",
    },
    Kolkata: {
        "cityId": 162,
        "stateId": 10,
        "title": "Kolkata",
        "pinCode": "700001",
        "locationCoordinates": {
            "lon": 88.3517780680498,
            "lat": 22.5560638443983
        },
        "slugName": "kolkata",
    },
    Kurnool: {
        "cityId": 582,
        "stateId": 22,
        "title": "Kurnool",
        "pinCode": "518001",
        "locationCoordinates": {
            "lon": 78.0373,
            "lat": 15.8281
        },
        "slugName": "kurnool"
    },
    Mahbubnagar: {
        "cityId": 2,
        "stateId": 23,
        "title": "Mahbubnagar",
        "pinCode": "509001",
        "locationCoordinates": {
            "lon": 78.0035,
            "lat": 16.7488
        },
        "slugName": "mahbubnagar"
    },
    Mancherial: {
        "cityId": 6,
        "stateId": 23,
        "title": "Mancherial",
        "pinCode": "504208",
        "locationCoordinates": {
            "lon": 79.4443,
            "lat": 18.8714
        },
        "slugName": "mancherial"
    },
    Nandyal: {
        "cityId": 868,
        "stateId": 22,
        "title": "Nandyal",
        "pinCode": "518501",
        "locationCoordinates": {
            "lon": 78.4873,
            "lat": 15.4777
        },
        "slugName": "nandyal"
    },
    Nellore: {
        "cityId": 558,
        "stateId": 22,
        "title": "Nellore",
        "pinCode": "524001",
        "locationCoordinates": {
            "lon": 79.9865,
            "lat": 14.4426
        },
        "slugName": "nellore"
    },
    Nizamabad: {
        "cityId": 5,
        "stateId": 23,
        "title": "Nizamabad",
        "pinCode": "503001",
        "locationCoordinates": {
            "lon": 78.0941,
            "lat": 18.6725
        },
        "slugName": "nizamabad"
    },
    Rajahmundry: {
        "cityId": 566,
        "stateId": 22,
        "title": "Rajahmundry",
        "pinCode": "533101",
        "locationCoordinates": {
            "lon": 81.8040,
            "lat": 17.0005
        },
        "slugName": "rajahmundry"
    },
    Sangareddy: {
        "cityId": 867,
        "stateId": 23,
        "title": "Sangareddy",
        "pinCode": "502001",
        "locationCoordinates": {
            "lon": 78.0816,
            "lat": 17.6140
        },
        "slugName": "sangareddy"
    },
    Shadnagar: {
        "cityId": 869,
        "stateId": 23,
        "title": "Shadnagar",
        "pinCode": "509216",
        "locationCoordinates": {
            "lon": 78.2059,
            "lat": 17.0713
        },
        "slugName": "shadnagar"
    },
    Tirupathi: {
        "cityId": 537,
        "stateId": 22,
        "title": "Tirupathi",
        "pinCode": "517501",
        "locationCoordinates": {
            "lon": 79.4192,
            "lat": 13.6288
        },
        "slugName": "tirupathi"
    },
    Vishakapatnam: {
        "cityId": 594,
        "stateId": 22,
        "title": "Visakhapatnam",
        "pinCode": "530001",
        "locationCoordinates": {
            "lon": 83.2961,
            "lat": 17.6998
        },
        "slugName": "visakhapatnam"
    },
    Wanaparthy: {
        "cityId": 866,
        "stateId": 23,
        "title": "Wanaparthy",
        "pinCode": "509103",
        "locationCoordinates": {
            "lon": 78.06188,
            "lat": 16.360117
        },
        "slugName": "wanaparthy"
    },
    Warangal: {
        "cityId": 476,
        "stateId": 23,
        "title": "Warangal",
        "pinCode": "506001",
        "locationCoordinates": {
            "lon": 79.5941,
            "lat": 17.9689
        },
        "slugName": "warangal"
    }
}
const popularCity = [
    cities.Gulbarga,
    cities.Gurugram,
    cities.Hanmakonda,
    cities.Hyderabad,
    cities.Karimnagar,
    cities.Kolkata,
    cities.Kurnool,
    cities.Mahbubnagar,
    cities.Mancherial,
    cities.Nandyal,
    cities.Nellore,
    cities.Nizamabad,
    cities.Rajahmundry,
    cities.Sangareddy,
    cities.Shadnagar,
    cities.Tirupathi,
    cities.Vishakapatnam,
    cities.Wanaparthy,
    cities.Warangal
]
exports["popularCity"] = popularCity;
exports["cities"] = cities;
