import { CryptoUtil } from "./cryptoutil";

export default class StorageUtil {

    public static LOGIN_KEY = 'a_k';
    public static TYPE_RAPTOR = 'raptor';
    public static TYPE_LOGOUT = 'logout';
    public static DEFAULT_LANGUAGE = 'defaultLanguage_';
    public static TYPE_KEY = 'type';
    public static TYPE_SELECTED_SERVICE_LIST = 'selectedServiceList';
    public static TYPE_SELECTED_MEDICINE_LIST = 'selectedMedicineList';
    public static TYPE_SERVICES_ADDED_CART = 'servicesAddedToCart';
    public static TYPE_SELECTED_POC_ID = 'selectedpocId';
    public static TYPE_SELECTED_SERVICE = 'initialSelectedService';
    public static TYPE_VIDEO_START_TIME = 'videoStartTime';
    public static TYPE_SELECTED_PRODUCT_LIST = 'selectedProductList';
    public static TYPE_SELECTED_WELLNESS_POC = 'selectedwelnesspocId';
    public static TYPE_SELECTED_WELLNESS_LIST = 'selectedWellnessServiceList';
    public static TYPE_TAB_CLOSE_TIME = 'tabCloseTime';


    public static toString(obj: any) {
        if (!obj) return "";
        return JSON.stringify(obj)
    }

    public static setDataLocalStorage(key: string, data: any) {
        try {
            if (typeof window !== "undefined") {
                window.localStorage.removeItem(key);
                window.localStorage.setItem(key, CryptoUtil.encryptData(JSON.stringify(data, StorageUtil.replacer)));
            }
        } catch (error) {
            console.error(error);
        }
    }

    public static removeItemLocalStorage(key: string) {
        try {
            if (typeof window !== "undefined") {
                window.localStorage.removeItem(key);
            }
        } catch (error) {
            console.error(error);
        }
    }

    public static getDataLocalStorage(key: string) {
        let parsedData: any = null;
        try {
            if (typeof window !== "undefined") {
                let data = CryptoUtil.decryptData(window.localStorage.getItem(key));
                try {
                    if (data?.length > 0) {
                        parsedData = JSON.parse(data, StorageUtil.reviver);
                    }
                } catch (error) {
                    console.log(`Error occurred while assingning ${key} state`, error);
                }
            }
        } catch (error) {
            console.error(error);
        }
        return parsedData;
    }

    public static setStringDataLocalStorage(key: string, data: string) {
        try {
            if (typeof window !== "undefined") {
                window.localStorage.removeItem(key);
                window.localStorage.setItem(key, CryptoUtil.encryptData(data));
            }
        } catch (error) {
            console.error(error);
        }
    }

    public static getStringDataLocalStorage(key: string) {
        let parsedData = '';
        try {
            if (typeof window !== "undefined") {
                parsedData = CryptoUtil.decryptData(window.localStorage.getItem(key));
            }
        } catch (error) {
            console.error(error);
        }
        return parsedData;
    }

    public static clearData() {
        try {
            if (typeof window !== "undefined") {
                window.localStorage.removeItem(StorageUtil.LOGIN_KEY);
                window.localStorage.removeItem(StorageUtil.TYPE_RAPTOR);
                window.localStorage.removeItem(StorageUtil.TYPE_LOGOUT);
                window.localStorage.removeItem(StorageUtil.TYPE_KEY);
                window.localStorage.removeItem(StorageUtil.TYPE_SELECTED_SERVICE_LIST);
                window.localStorage.removeItem(StorageUtil.TYPE_SELECTED_MEDICINE_LIST);
                window.localStorage.removeItem(StorageUtil.TYPE_SERVICES_ADDED_CART);
                window.localStorage.removeItem(StorageUtil.TYPE_SELECTED_POC_ID);
                window.localStorage.removeItem(StorageUtil.TYPE_SELECTED_SERVICE);
                window.localStorage.removeItem(StorageUtil.TYPE_VIDEO_START_TIME);
                window.localStorage.removeItem(StorageUtil.TYPE_SELECTED_PRODUCT_LIST);
                window.localStorage.removeItem(StorageUtil.TYPE_SELECTED_WELLNESS_POC);
                window.localStorage.removeItem(StorageUtil.TYPE_SELECTED_WELLNESS_LIST);
            }
        } catch (error) {
            console.error(error);
        }
    }

    public static replacer(key, value) {
        if (value instanceof Map) {
            return {
                dataType: 'Map',
                value: Array.from(value.entries()), // or with spread: value: [...value]
            };
        } else {
            return value;
        }
    }

    public static reviver(key, value) {
        if (typeof value === 'object' && value !== null) {
            if (value.dataType === 'Map') {
                return new Map(value.value);
            }
        }
        return value;
    }

    public static setUnencryptedLocalStorage(key: string, data: any) {
        try {
            if (typeof window !== "undefined") {
                window.localStorage.removeItem(key);
                window.localStorage.setItem(key, JSON.stringify(data));
            }
        } catch (error) {
            console.error(error);
        }
    }

    public static getUnencryptedLocalStorageData(key: string) {
        let parsedData: any = null;
        try {
            if (typeof window !== "undefined") {
                parsedData = window.localStorage.getItem(key);
            }
        } catch (error) {
            console.error(error);
        }
        return parsedData;
    }

}