export class BasketDiscount {

    public static DOCTOR_FOLLOWUP = 0;
    public static PARTNER_DISCOUNT = BasketDiscount.DOCTOR_FOLLOWUP + 1; //other discount
    public static COUPON_DISCOUNT = BasketDiscount.PARTNER_DISCOUNT + 1;
    public static PROMOTIONAL_DISCOUNT = BasketDiscount.COUPON_DISCOUNT + 1;
    public static PRIVELEGE_DISCOUNT = 6;

    public id: number;
    public type: number;
    public name: string;
    public discountAmount: number;
    public percent: number;
    public validFrom: number;
    public validTo: number;
    public profileId: number;
    public valueDiscount: boolean;
    public cashbackAmount: number;
    public referenceType: number;
}
