import Router from 'next/router';

export default class RouterUtil {
    public static getQuery() {
        if (typeof window === "undefined") return {};
        if (Router && Router.query && JSON.stringify(Router.query) != '{}') return Router.query || {};
        if (window.location.search) {
            let query: any = {};
            let searchParam = new URLSearchParams(window.location.search);
            searchParam.forEach((value, key) => query[key] = value);
            return query || {};
        }
    }

    public static getPathName() {
        if (typeof window === "undefined") return null;
        return Router.pathname;
    }
}