const NextI18Next = require('next-i18next').default
const { localeSubpaths } = require('next/config').default().publicRuntimeConfig
const path = require('path')

module.exports = new NextI18Next({
  defaultLanguage	 : 'en',
  otherLanguages: ['kn','te','hi','ta','bn','or','mr','fr'],
  languages: ['en', 'kn','te','hi','ta','bn','or','mr','fr'],
  fallbackLng: 'en',
  localeSubpaths,
  localePath: path.resolve('./public/static/locales'),
  defaultNS : 'common',
  // shallowRender: true
  detection:{
    order:["querystring" , "cookie" , "header"],
    lookupCookie:"next-i18next",
    cookieExpirationDate: new Date('2030-01-01'),
    cookieSecure: true, // if need secure cookie
    cookieSameSite: 'Lax' // 'strict', 'lax' or 'none'
  }
}) 