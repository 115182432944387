"use strict";
exports.__esModule = true;
const PortalConfig = require('./portal_config');
var Config = /** @class */ (function () {
    function Config() { }

    Config.DEV = 0;
    Config.AWS_QA = 1;
    Config.AWS_UAT = 2;
    Config.LIVE = 3;

    Config.TEST_TYPE = Config.AWS_QA;

    Config.portal = PortalConfig.default.VDC;

    Config.VERSION = Config.portal.version;

    return Config;
}());
exports.Config = Config;
