import { Config } from "./config";
import http from "axios";
import { BaseResponse } from '../../model/base/baseresponse';
import Auth from '../../auth/auth';
import { Source } from '../../model/base/source';
import { i18n } from '../../../i18n';
import AuthTypes from "../../auth/auth.types";
import StorageUtil from "../../auth/util/storageUtil";
import * as CryptoJS from 'crypto-js';

export default class AxiosService {

    private static config: any = { timeout: 60000 };

    public static httpGet(path: string, params: any = {}, reqOptions: any = {}): Promise<BaseResponse> {
        let options = { ...AxiosService.config, params, ...reqOptions, ...AxiosService.checkAndSetSessionId(path, params, true) };
        return new Promise((resolve, reject) => {
            http.get(path, options)
                .then(res => {
                    if (res.status == 401 || res.status == 403) {
                        if (res.status == 401) {
                            Auth.getDispatchState({ type: AuthTypes.SET_LOGOUT });
                            StorageUtil.removeItemLocalStorage(StorageUtil.LOGIN_KEY);
                        }
                        // Auth.navigateTo('/login');
                        resolve({ data: null, statusCode: res.status, statusMessage: res.statusText } as BaseResponse);
                    }
                    resolve({ data: res.data, statusCode: res.status, statusMessage: res.statusText } as BaseResponse);
                }).catch((err) => {
                    if (err?.name == "Error" && (err?.message?.includes(401) || err?.message?.includes(403))) {
                        if (err?.message?.includes(401)) {
                            Auth.getDispatchState({ type: AuthTypes.SET_LOGOUT });
                            StorageUtil.removeItemLocalStorage(StorageUtil.LOGIN_KEY);
                        }
                        if (!(typeof window === 'undefined'))
                            Auth.navigateTo('/login');
                    }
                    reject(err);
                });
        });
    }

    public static httpPost(path: string, reqBody: any, reqOptions: any = {}): Promise<BaseResponse> {
        if (Config.TEST_TYPE >= Config.LIVE) {
            reqOptions = { ...reqOptions, crossDomain: false }
        }
        let options = { ...AxiosService.config, ...reqOptions, ...AxiosService.checkAndSetSessionId(path, reqBody, false) };
        return new Promise((resolve, reject) => {
            http.post(path, reqBody, options).then(res => {
                if (res.status == 401 || res.status == 403) {
                    if (res.status == 401) {
                        Auth.getDispatchState({ type: AuthTypes.SET_LOGOUT });
                        StorageUtil.removeItemLocalStorage(StorageUtil.LOGIN_KEY);
                    }
                    // Auth.navigateTo('/login');
                    resolve({ data: null, statusCode: res.status, statusMessage: res.statusText } as BaseResponse);
                }
                resolve({ data: res.data, statusCode: res.status, statusMessage: res.statusText } as BaseResponse);
            }).catch((err) => {
                if (err?.name == "Error" && (err?.message?.includes(401) || err?.message?.includes(403))) {
                    if (err?.message?.includes(401)) {
                        Auth.getDispatchState({ type: AuthTypes.SET_LOGOUT });
                        StorageUtil.removeItemLocalStorage(StorageUtil.LOGIN_KEY);
                    }
                    if (!(typeof window === 'undefined'))
                        Auth.navigateTo('/login');
                }
                reject(err);
            })
        });
    }
    public static checkAndSetSessionId(path, reqBody, isGet) {
        let options;
        let sessionid = AxiosService.getSessionId();
        let headerOptions = {
            'Content-Type': 'application/json',
            'versioncode': Config.portal.versionCode,
            'sourceapp': Source.CONSUMER_PORTAL,
            'Accept-Language': i18n?.language ? i18n.language : 'En'
        }
        if (sessionid) {
            options = {
                headers: {
                    ...headerOptions,
                    sessionid,
                    'request-signature': AxiosService.getRequestHash(path, reqBody, sessionid, isGet)
                }
            };
        } else {
            options = {
                headers: { ...headerOptions }
            };
        }
        return options;
    }

    private static getSessionId(): any {
        return Auth.getSessionId();
    }

    private static getRequestHash(path: string, params: any = {}, sessionToken, isGet) {
        try {
            let request = params ? params : {};

            if (isGet) {
                if (request) {
                    for (let key in request) {
                        if (request[key] == undefined || request[key] == null) {
                            delete request[key];
                            delete params[key];
                        }
                    }
                }
                let pathParams = AxiosService.getQueryParams(path);
                let tmp = {};
                for (var key in request) {
                    tmp[key] = `${request[key]}`;
                }
                if (pathParams) {
                    for (var key in pathParams) {
                        tmp[key] = `${pathParams[key]}`;
                    }
                }
                request = tmp;
            }
            return AxiosService.generateRequestHash(JSON.stringify(request), sessionToken);
        } catch (err) {
            console.log(err);
        }
    }

    private static getQueryParams(path) {
        if (path.indexOf('?') > 0) {
            let tmpPath = path.substring(path.indexOf('?'));
            const params = new URLSearchParams(tmpPath);
            let tmpObj = {};
            for (const param of params.entries()) {
                tmpObj[param[0]] = `${param[1]}`;
            }
            return tmpObj;
        } else {
            return {};
        }
    }

    private static generateRequestHash(request, sessionToken) {
        let salt = sessionToken.substring(1, 7) + sessionToken.substring(30, 46);
        var sha256 = CryptoJS.SHA256(salt + request);
        return sha256.toString();
    }

    public static uploadFile(fileUrl: string, data): Promise<BaseResponse> {
        let options = { ...AxiosService.config };
        return new Promise((resolve, reject) => {
            http.put(fileUrl, data, options).then(res => {
                if (res.status == 401 || res.status == 403) {
                    if (res.status == 401) {
                        Auth.getDispatchState({ type: AuthTypes.SET_LOGOUT });
                        StorageUtil.removeItemLocalStorage(StorageUtil.LOGIN_KEY);
                    }
                    return;
                }
                resolve({ data: res.data, statusCode: res.status, statusMessage: res.statusText } as BaseResponse);
            }).catch((err) => {
                if (err?.name == "Error" && (err?.message?.includes(401) || err?.message?.includes(403))) {
                    if (err?.message?.includes(401)) {
                        Auth.getDispatchState({ type: AuthTypes.SET_LOGOUT });
                        StorageUtil.removeItemLocalStorage(StorageUtil.LOGIN_KEY);
                    }
                    if (!(typeof window === 'undefined'))
                        Auth.navigateTo('/login');
                }
                reject(err);
            })
        });
    }

    public static httpExternalGet(path: string, params: any = {}, reqOptions: any = {}): Promise<BaseResponse> {
        let options = { ...AxiosService.config, params, ...reqOptions };
        return new Promise((resolve, reject) => {
            http.get(path, options)
                .then(res => {
                    if (res.status == 401 || res.status == 403) {
                        if (res.status == 401) {
                            Auth.getDispatchState({ type: AuthTypes.SET_LOGOUT });
                            StorageUtil.removeItemLocalStorage(StorageUtil.LOGIN_KEY);
                        }
                        // Auth.navigateTo('/login');
                        resolve({ data: null, statusCode: res.status, statusMessage: res.statusText } as BaseResponse);
                    }
                    resolve({ data: res.data, statusCode: res.status, statusMessage: res.statusText } as BaseResponse);
                }).catch((err) => {
                    if (err?.name == "Error" && (err?.message?.includes(401) || err?.message?.includes(403))) {
                        if (err?.message?.includes(401)) {
                            Auth.getDispatchState({ type: AuthTypes.SET_LOGOUT });
                            StorageUtil.removeItemLocalStorage(StorageUtil.LOGIN_KEY);
                        }
                        if (!(typeof window === 'undefined'))
                            Auth.navigateTo('/login');
                    }
                    reject(err);
                });
        });
    }
}