import Router from 'next/router';
// import {Router} from "../../i18n";
import { extractPath } from "../../deepLink";
import { ReduxAuth } from './reduxauth';
import { Config } from '../base/portal_env_config/config';
export default class AuthGuard extends ReduxAuth {

    public static routeGuard(ctx, fallbackURL = '/'): boolean {
        switch (ctx.pathname) {
            case "/subscriptions":
                if (!AuthGuard.canActivatePackage(ctx)) {
                    AuthGuard.navigateTo(fallbackURL, ctx);
                    return true; //ProtectedRoute
                }
                break;
            case "/subscriptions/packagelist":
                if (!AuthGuard.canActivatePackageList(ctx)) {
                    AuthGuard.navigateTo(fallbackURL, ctx);
                    return true; //ProtectedRoute
                }
                break;
            case "/wellness":
                if (!AuthGuard.canActivateWellness(ctx)) {
                    AuthGuard.navigateTo(fallbackURL, ctx);
                    return true; //ProtectedRoute
                }
                break;
            case "/servicedetails":
                if (!AuthGuard.canActivateWellnessServiceDetails(ctx)) {
                    AuthGuard.navigateTo(fallbackURL, ctx);
                    return true; //ProtectedRoute
                }
                break;

            case "/booking/doctor":
                if (!AuthGuard.canActivateDoctorBooking(ctx)) {
                    AuthGuard.navigateTo(fallbackURL, ctx);
                    return true; //ProtectedRoute
                }
                break;

            default:
                return false;
        }
        return false; //UnProtectedRoute
    }
    public static canActivatePackageList(ctx): boolean {
        // if(Auth.isAuthenticated()){
        return true;
        // }
        // return false;
    }
    public static canActivatePackage(ctx): boolean {
        // if(Auth.isAuthenticated()){
        return true;
        // }
        // return false;
    }
    public static canActivateWellness(ctx): boolean {
        return true;
    }
    public static canActivateWellnessServiceDetails(ctx): boolean {
        return true;
    }
    public static canActivateDoctorBooking(ctx): boolean {
        // if(Auth.isAuthenticated()){
        return true;
        // }
        // return false;
    }

    public static navigateTo(path: string, ctx: any = undefined, header: any = undefined) {
        if (typeof window !== "undefined") {
            path = extractPath(path, (process.env.staticDeployment || Config?.portal?.staticDeployment), Config?.portal?.menuConfig, header);
            if (ctx && ctx.req && ctx.res.writeHead) {
                ctx.res.writeHead(302, { Location: path });
                ctx.res.end();
            } else if (Router && Router.push) {
                Router.push(path);
            }
        }
    }
}