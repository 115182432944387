exports.extractPath = function (pathname, isStaticDeployment, menuConfig, header = undefined) {
    console.log("Config: ", pathname + " header: ", header);
    let paths;
    if (pathname.includes('://')) {
        switch (true) {
            case /.*:\/\/commerce\/packages/.test(pathname):// my3c://commerce/packages
                if (menuConfig && !(menuConfig.PACKAGES.enabled)) {
                    pathname = '';
                    return pathname;
                }
                paths = pathname.split('/');// ["","my3c:", "", "commerce", "packages", "118"]
                pathname = '/subscriptions';
                return pathname;
            case /.*:\/\/commerce\/packages\/\d+/.test(pathname):// my3c://commerce/packages/119
                if (menuConfig && !(menuConfig.PACKAGES.enabled)) {
                    pathname = '';
                    return pathname;
                }
                paths = pathname.split('/');// ["","my3c:", "", "commerce", "packages", "118"]
                pathname = '/subscriptions/packagedetails' + (isStaticDeployment ? '/' : '') + '?packageID=' + paths[5];
                return pathname;
            case /.*:\/\/commerce\/imagepackagedetails\/\d+/.test(pathname):// "my3c://commerce/imagepackagedetails/4005"
                if (menuConfig && !(menuConfig.PACKAGES.enabled)) {
                    pathname = '';
                    return pathname;
                }
                paths = pathname.split('/');// ["","my3c:", "", "commerce", "imagepackagedetails", "118"]
                pathname = '/subscriptions/packagedetails' + (isStaticDeployment ? '/' : '') + '?packageID=' + paths[5];
                return pathname;
            case /.*:\/\/commerce\/diagnosticpackages/.test(pathname):// my3c://commerce/diagnosticpackages
                if (menuConfig && !(menuConfig.LAB_WALKIN.enabled)) {
                    pathname = '';
                    return pathname;
                }
                pathname = '/diagnostic/walkin/listview' + (isStaticDeployment ? '/' : '') + '?type=2';
                return pathname;
            case /.*:\/\/booking\/diagnostic\/walkincategory\/\d+\/\d+/.test(pathname):// my3c://booking/diagnostic/walkincategory/30000/14101
                if (menuConfig && !(menuConfig.LAB_WALKIN.enabled)) {
                    pathname = '';
                    return pathname;
                }
                paths = pathname.split('/');// ["","my3c:", "", "booking", "diagnostic", "walkincategory", "30000", "14101"]
                pathname = '/diagnostic/walkin/listview' + (isStaticDeployment ? '/' : '') + '?type=3&departmentId=' + paths[6] + "&categoryId=" + paths[7];
                return pathname;
            // case /.*:\/\/test/.test(pathname):// my3c://booking/diagnostic/walkin
            case /.*:\/\/booking\/diagnostic\/walkin/.test(pathname):// my3c://booking/diagnostic/walkin
                if (menuConfig && !(menuConfig.LAB_WALKIN.enabled)) {
                    pathname = '';
                    return pathname;
                }
                pathname = '/test';
                return pathname;
            case /.*:\/\/booking\/diagnostic\/home/.test(pathname):// my3c://booking/diagnostic/home
                if (menuConfig && !(menuConfig.LAB_HOME.enabled)) {
                    pathname = '';
                    return pathname;
                }
                pathname = '/diagnostic/home-sample-collection';
                return pathname;
            case /.*:\/\/booking\/diagnostic\/packages/.test(pathname):// vdc://booking/diagnostic/packages
                if (menuConfig && !(menuConfig.LAB_HOME.enabled)) {
                    pathname = '';
                    return pathname;
                }
                pathname = '/health-checkup';
                return pathname;
            case /.*:\/\/booking\/diagnostic\/upload/.test(pathname):// vdc://booking/diagnostic/packages
                if (menuConfig && !(menuConfig.LAB_HOME.enabled)) {
                    pathname = '';
                    return pathname;
                }
                pathname = '/diagnostic/upload';
                return pathname;
            case /.*:\/\/booking\/diagnostic\/package\/([A-Z a-z0-9%()-])/.test(pathname):// "vdc://booking/diagnostic/package/vijaya-cardiac-profile"
                paths = pathname.split('/');// ["","vdc:", "", "booking", "diagnostic", "package", "vijaya-cardiac-profile"]
                pathname = `health-checkup/${header}/${paths[6]}`;
                return pathname;
            // case /.*:\/\/booking\/diagnostic\/package\/\d+\/\d+/.test(pathname):// "vdc://booking/diagnostic/package/4/3646000134/46"
            //     paths = pathname.split('/');// ["","vdc:", "", "booking", "diagnostic", "package", "4", "3646000134","46"]
            //     pathname = `/dashboard/servicedetails/${header.replace(/\s/g, '-').toLocaleLowerCase()}` + (isStaticDeployment ? '/' : '') + '?serviceId=' + paths[7] + "&brandId=" + paths[8];
            //     return pathname;
            // case /.*:\/\/booking\/diagnostic\/package\/\d+\/\d+/.test(pathname):// my3c://booking/diagnostic/package/4/1000344
            //     if (menuConfig && !(menuConfig.LAB_HOME.enabled)) {
            //         pathname = '';
            //         return pathname;
            //     }
            //     paths = pathname.split('/');// ["","my3c:", "", "booking", "diagnostic", "package", "4", "1000344"]
            //     if (paths[7] && header) {
            //         pathname = `/dashboard/servicedetails/${header.replace(/\s/g, '-').toLocaleLowerCase()}` + (isStaticDeployment ? '/' : '') + '?serviceId=' + paths[7] + "&categoryId=" + paths[6];
            //     }
            //     return pathname;
            // case /.*:\/\/booking\/diagnostic\/package\/\d+\/\d+/.test(pathname):// "my3c://booking/diagnostic/package/4/10003505/18"
            //     if (menuConfig && !(menuConfig.LAB_HOME.enabled)) {
            //         pathname = '';
            //         return pathname;
            //     }
            //     paths = pathname.split('/');// ["","my3c:", "", "booking", "diagnostic", "package", "4", "10003505","18"]
            //     pathname = `/dashboard/servicedetails/${header.replace(/\s/g, '-').toLocaleLowerCase()}` + (isStaticDeployment ? '/' : '') + '?serviceId=' + paths[7] + "&categoryId=" + paths[6] + "&brandId=" + paths[8];
            //     return pathname;
            //Doctor booking
            case /.*:(\/\/booking\/doctor\/\d+\/)([A-Z a-z0-9%()]+\/)(\d+)/.test(pathname)://"my3c://booking/doctor/21/General Physician/211"
                if (menuConfig && !(menuConfig.DOCTOR_WALKIN.enabled)) {
                    pathname = '';
                    return pathname;
                }
                paths = pathname.split('/');//["","my3c:", "", "booking", "doctor", "categoryd", "General Physician", "211"]
                pathname = '/booking/inperson/doctorlist' + (isStaticDeployment ? '/' : '') + '?serviceId=' + paths[7] + '&serviceName=' + paths[6];
                return pathname;
            case /.*:(\/\/booking\/doctor\/)([A-Z a-z0-9%()]+\/)(\d+)/.test(pathname)://"my3c://booking/doctor/General%20Physician/211"
                if (menuConfig && !(menuConfig.DOCTOR_WALKIN.enabled)) {
                    pathname = '';
                    return pathname;
                }
                paths = pathname.split('/');//["","my3c:", "", "booking", "doctor", "General Physician", "211"]
                pathname = '/booking/inperson/doctorlist' + (isStaticDeployment ? '/' : '') + '?serviceId=' + paths[6] + '&serviceName=' + paths[5];
                return pathname;
            case /.*:(\/\/booking\/video\/\d+\/)([A-Z a-z0-9%()]+\/)(\d+)/.test(pathname)://my3c://booking/video/3/General%20Physician/211"
                if (menuConfig && !(menuConfig.DOCTOR_VIDEO.enabled)) {
                    pathname = '';
                    return pathname;
                }
                paths = pathname.split('/');//["","my3c:", "", "booking", "video", "categoryd", "ServiceName", "serviceId"]
                pathname = '/booking/video/doctorlist' + (isStaticDeployment ? '/' : '') + '?serviceId=' + paths[7] + '&serviceName=' + paths[6];
                return pathname;
            case /.*:\/\/booking\/doctor/.test(pathname)://my3c://booking/doctor
                if (menuConfig && !(menuConfig.DOCTOR_WALKIN.enabled)) {
                    pathname = '';
                    return pathname;
                }
                pathname = '/booking/inperson';
                return pathname;
            case /.*:\/\/booking\/video\/\d+/.test(pathname)://my3c://booking/video/-1(id)
                if (menuConfig && !(menuConfig.DOCTOR_VIDEO.enabled)) {
                    pathname = '';
                    return pathname;
                }
                pathname = '/booking/video';
                return pathname;
            case /.*:\/\/booking\/video/.test(pathname)://my3c://booking/video
                if (menuConfig && !(menuConfig.DOCTOR_VIDEO.enabled)) {
                    pathname = '';
                    return pathname;
                }
                pathname = '/booking/video';
                return pathname;
            case /.*:(\/\/booking\/connectnow\/\d+\/)([A-Z a-z0-9%]+\/)(\d+)/.test(pathname)://my3c://booking/connectnow/categoryd/ServiceName/serviceId
                pathname = '';
                return pathname;
            case /.*:\/\/booking\/connectnow/.test(pathname)://"my3c://booking/connectnow
                pathname = '';
                return pathname;
            case /.*:\/\/booking\/healthcheckup\/dept\/(\d+)/.test(pathname)://vdc://booking/healthcheckup/dept/30004
                console.log("Insidee");
                paths = pathname.split('/');
                pathname = '/health-checkup/dept/' + paths[6];
                return pathname;
            case /.*:\/\/booking\/healthcheckup/.test(pathname)://booking/healthcheckup
                pathname = '/health-checkup';
                return pathname;
            case /.*:\/\/commerce\/fitness\/\d+/.test(pathname)://my3c://commerce/fitness/0
                if (menuConfig && !(menuConfig.PRODUCT.enabled)) {
                    pathname = '';
                    return pathname;
                }
                pathname = '/product';
                return pathname;
            case /.*:\/\/commerce\/pharmacy/.test(pathname)://my3c://commerce/pharmacy
                if (menuConfig && !(menuConfig.PHARMACY.enabled)) {
                    pathname = '';
                    return pathname;
                }
                pathname = '/pharmacy';
                return pathname;
            case /.*:\/\/profile\/consultations/.test(pathname)://my3c://profile/consults
                if (menuConfig && !(menuConfig.MENU_MYCONSULTS.enabled)) {
                    pathname = '';
                    return pathname;
                }
                pathname = '/profile/consultations/myconsultations';
                return pathname;
            case /.*:\/\/profile\/reports/.test(pathname)://my3c://profile/reports
                if (menuConfig && !(menuConfig.MENU_HEALTHLOCKER.enabled)) {
                    pathname = '';
                    return pathname;
                }
                pathname = '/profile/healthlocker/?type=prescription';
                return pathname;
            //Wellness
            case /.*:\/\/booking\/wellness/.test(pathname)://my3c://booking/wellness
                if (menuConfig && !(menuConfig.WELLNESS.enabled)) {
                    pathname = '';
                    return pathname;
                }
                pathname = '/wellness';
                return pathname;
            //Homecare
            case /.*:\/\/booking\/homecare/.test(pathname)://my3c://booking/homecare
                pathname = '/';
                return pathname;
            //Nearby POC
            case /.*:(\/\/booking\/nearby\/\d+\/)([A-Z a-z0-9%,-.]+\/)(\d+\/\d+)/.test(pathname)://my3c://booking/nearby/3(type)/Surya%20Kumar(pocName)/4(pocId)/5(brandId)
                paths = pathname.split('/');//["","my3c:","","booking","nearby","3","Surya%20Kumar","4","5"]
                let type = paths[5];
                console.log("DeepLink: ", paths[5]);
                switch (+type) {
                    case 3:
                        pathname = '/dashboard/clinic?pocId=' + paths[7] + "&pocName=" + paths[6] + "&brandId=" + paths[8];
                        return pathname;
                    case 2:
                        pathname = '/dashboard/diagnostics?pocId=' + paths[7] + "&pocName=" + paths[6] + "&brandId=" + paths[8];
                        return pathname;
                    case 4:
                        pathname = '';
                        return pathname;
                    default:
                        return '/';
                }
            // return pathname;
            case /.*:\/\/booking\/nearby\/\d+/.test(pathname)://my3c://booking/nearby/2(type)
                paths = pathname.split('/');
                pathname = '';
                return pathname;
            case /.*:\/\/feedback/.test(pathname)://oliva://feedback/ORMYD27969
                paths = pathname.split('/');// ["","oliva:", "", "feedback", "ORMYD27969"]
                pathname = '/feedback' + (isStaticDeployment ? '/' : '') + '?orderId=' + paths[4];
                return pathname;
            case /.*:\/\/([A-Z a-z0-9%,-.]+\/)Hyderabad\/health-packages([?]packagecategory[=][a-z0-9&]+)city=Hyderabad/.test(pathname):// https://www.vijayadiagnostic.com/Hyderabad/health-packages?packagecategory=5c9d156149a4ace73bc72df1&city=Hyderabad
                return '/health-checkup';
            // https://www.vijayadiagnostic.com/hyderabad/vijaya-whole-body-checkup-package
            case /.*:\/\/([A-Z a-z0-9%,-.]+\/)hyderabad\/([A-Z a-z-])/.test(pathname):
                paths = pathname.split('/')
                let str = paths[4];
                let packName = ''
                const lastIndexOfSpace = str.lastIndexOf('-');
                if (lastIndexOfSpace === -1) {
                    packName = str;
                }
                packName = str.substring(0, lastIndexOfSpace);
                return `/health-checkup/hyderabad/${packName}`;
            case /.*:\/\/([A-Z a-z0-9%,-.]+\/)([A-Z a-z-]+\/)([A-Z a-z0-9%-])/.test(pathname)://https://www.vijayadiagnostic.com/Hyderabad/center-locator
                paths = pathname.split('/');//["https:","","VDC","Hyderabad","center-locater"]
                let considerableString = paths[4];
                console.log("DeepLink: ", paths[4] + ">>>: ", paths[3]);
                if (paths[3] === 'services') {
                    pathname = 'services/' + paths[4];
                    return pathname;
                }
                switch (considerableString) {
                    case 'center-locator':
                        pathname = '/find-a-centre';
                        return pathname;
                    case 'Hyderabad':
                        let otherString = paths[3]
                        console.log("DeepLinkInside: ", paths[3]);
                        switch (otherString) {
                            case 'about-us':
                                pathname = '/about-us'
                                return pathname;
                            case 'accreditation':
                                pathname = '/accreditation'
                                return pathname;
                            case 'faqs':
                                pathname = '/faqs'
                                return pathname;
                            case 'privacy-policy':
                                pathname = '/privacy-policy'
                                return pathname;
                            case 'terms-conditions':
                                pathname = '/terms-condition'
                                return pathname;
                            default:
                                return '/';
                        }
                    // case 'blog':
                    //     pathname = '/blog';
                    //     return pathname;
                    // case 'awards-and-accolades':
                    //     pathname = '/awards-and-accolades';
                    //     return pathname;
                    default:
                        return '/';
                }
            case /.*:\/\/([A-Z a-z0-9%,-.]+\/)([A-Z a-z0-9%-])/.test(pathname):// https://www.vijayadiagnostic.com/list-of-tests
                paths = pathname.split('/');//["https:","","VDC","list-of-tests"]
                let typeString = paths[3];
                console.log("DeepLink: ", paths[3]);
                switch (typeString) {
                    case 'list-of-tests':
                        pathname = '/test';
                        return pathname;
                    // case 'services':
                    //     pathname = '/services';
                    //     return pathname;
                    // case 'blog':
                    //     pathname = '/info/blog';
                    //     return pathname;
                    // case 'awards-and-accolades':
                    //     pathname = '/info/awards-and-accolades';
                    //     return pathname;
                    // case 'careers':
                    //     pathname = '/info/careers/';
                    //     return pathname;
                    // case 'csr-activity':
                    //     pathname = '/info/csr-activity/';
                    //     return pathname;
                    // case 'contact-us':
                    //     pathname = '/info/contact-us/';
                    //     return pathname;
                    // case 'corporate-office':
                    //     pathname = '/info/corporate-office/';
                    //     return pathname;
                    // case 'registered-office':
                    //     pathname = '/info/registered-office/';
                    //     return pathname;
                    // case 'generalenquiry':
                    //     pathname = '/info/generalenquiry/';
                    //     return pathname;
                    // case 'teleradiology':
                    //     pathname = '/info/teleradiology/';
                    //     return pathname;
                    default:
                        return '/';
                }

            // case /.*:\/\/([A-Z a-z0-9%,-.]+\/)([A-Z a-z]+\/)([A-Z a-z0-9%-]+\/)/.test(pathname):// https://www.vijayadiagnostic.com/Hyderabad/health-packages?packagecategory=5c9d156149a4ace73bc72df1&city=Hyderabad
            //     console.log("DeepLink: ", pathname);
            //     return pathname;
            default:
                return '/';
        }
    } else return pathname
}




